<template>
  <div class="app-main-nav clearfix">
    <Appyoutopnav />
    <Appyoutopdown />
    <div class="title ellipsis">{{ $t("homepage.title") }}</div>
    <Appcarousel />
    <div class="title1 ellipsis">{{ $t("homepage.title1") }}</div>
    <textarea
      rows="3"
      style="resize: none"
      cols="20"
      v-model="message"
      :placeholder="$t('homepage.textarea')"
      @keyup.enter="messageInput"
    ></textarea>
  </div>
</template>

<script>
import Appyoutopnav from "./components/Appyoutopnav.vue";
import Appyoutopdown from "./components/Appyoutopdown.vue";
import Appcarousel from "./components/Appcarousel.vue";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { addMessage } from "@/api/getData";
export default {
  name: "VueApptopnav",
  components: {
    Appyoutopnav,
    Appyoutopdown,
    Appcarousel,
  },
  setup() {
    const store = useStore();
    const obj = reactive({
      userinfo: store.state.User.userinfo,
      message: null,
      msg: {
        put_text: null,
        name: null,
      },
    });

    let stuList = JSON.parse(localStorage.getItem("student_list"));
    

    const messageInput = async () => {
      if (stuList.length > 0) {
        obj.msg.name = stuList[0].nickname;
      }
      obj.msg.put_text = obj.message;
      console.log(obj.msg);
      let res = await addMessage(obj.msg);

      obj.message = null;
    };

    return {
      ...toRefs(obj),
      messageInput,
    };
  },
};
</script>

<style lang="less" scoped>
.app-main-nav {
  box-sizing: border-box;
  padding-top: 45px;
  margin-left: 75px;
  max-width: 856px;
  width: 856px;
  .title {
    width: 100%;
    height: 72px;
    line-height: 72px;
    font-size: 22px;
    color: #5f84c7;
  }
  .title1 {
    width: 100%;
    height: 71px;
    line-height: 72px;
    color: #5f84c7;
    font-size: 22px;
  }
  textarea {
    width: 100%;
    height: 228px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    font-size: 16px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    background-color: #f7f7f7;
  }
}
</style>
