<template>
  <!-- 服务合同 -->
  <div class="contract_main_box">
    <div class="contract_box">1{{$t("contract.contract")}}</div>
    <div class="ctt_input">
      <div class="ctt_box_top">
        <div>{{$t("contract.text1")}}</div>
        <div></div>
      </div>
      <div class="ctt_box">
        <div>{{$t("contract.text2")}}</div>
        <div>
          <input type="text" />
        </div>
      </div>
      <div class="ctt_box">
        <div>{{$t("contract.text3")}}</div>
        <div>
          <input type="text" />
        </div>
      </div>
      <div class="ctt_down">
        {{$t("contract.text4")}} <i class="iconfont icon-xiazai"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
export default {
  name: "VueStudentContract",
  components: {},
  setup() {
    const router = useRouter();
    const obj = reactive({});

    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.contract_main_box {
  width: 905px;
  min-height: 80%;
  margin: 45px auto;
  box-sizing: border-box;
  .ctt_input {
    width: 500px;
    height: 200px;
    box-sizing: border-box;
    padding: 20px 10px;
    color: rgba(95, 132, 199, 1);
    .ctt_down {
      width: 100%;
      height: 50px;
      line-height: 60px;
      font-size: 22px;
      box-sizing: border-box;
      padding-left: 10px;
      cursor: pointer;
    }
    .ctt_box {
      width: 100%;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      div {
        flex: 1;
      }
      div:nth-child(1) {
        text-align: right;
        margin-right: 20px;
      }
      div:nth-child(2) {
        height: 80%;
        input {
          outline: none;
          width: 97%;
          height: 60%;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid rgba(95, 132, 199, 1);
        }
      }
    }
    .ctt_box_top {
      width: 100%;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      div {
        flex: 1;
      font-size: 22px;
      }
      div:nth-child(1) {
        text-align: right;
        margin-right: 20px;
      }
      div:nth-child(2) {
        width: 100px;
        height: 30px;
        background-color: rgba(240, 240, 240, 1);
        border-radius: 15px;
        margin-top: 5px;
        input{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .contract_box {
    width: 905px;
    height: 600px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 20px 40px;
    color: #a6a6a6;
    font-size: 24px;
  }
}
</style>
