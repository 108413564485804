<template>
    <!-- 3.主体区域 -->
    <div class="w main">
        <AppLeft/>
        <!-- 二级路由 -->
        <router-view></router-view>
    </div>
</template>

<script>
import AppLeft from "./components/Appleft.vue";

export default {
    name: 'VueLayout',
    components:{
        AppLeft
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.main{
    display:flex;
}
</style>