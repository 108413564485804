<template>
  <div class="Recharge_box">
    <AppUserHeader />
    
    <div v-loading="loading" style="min-height: 300px;">
    <div v-for="(itemType,index) in rechargeList" v-loading="loading" class="money_box">
      <div class="money_title">
        <span v-if="$t('index.pddate')==2">{{ itemType.course_en_name }}</span>
        <span v-else >{{ itemType.course_zh_name }}</span>
        <span v-if="$t('index.pddate')==2">{{ itemType.course_en_text }}</span>
        <span v-else >{{ itemType.course_zh_text }}</span>
      </div>
      <div class="money_content">
        <div v-for="(item,iindex) in itemType.course_list" class="monty_ctt_box" >
          <div>
            <span>{{item.sessions}}</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>{{item.amount.toFixed(2)}}USD</span>
          </div>
          <div>
            <span>({{item.unit_price.toFixed(2)}}USD/{{ $t("index.pddate")==2?"S":"节" }})</span>
          </div>
          <div>
            <span @click="toRecharge(index,iindex)">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div>
    </div>
    <!-- <div class="money_box">
      <div class="money_title">
        <span>{{ $t("recharge.money_title1") }}</span>
        <span>{{ $t("recharge.money_text1") }}</span>
      </div>
      <div class="money_content">
        <div class="monty_ctt_box">
          <div>
            <span>30</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>800.00USD</span>
          </div>
          <div>
            <span>(26.67USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>60</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>1500.00USD</span>
          </div>
          <div>
            <span>(25.00USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>90</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>2100.00USD</span>
          </div>
          <div>
            <span>(23.33USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div>
    <div class="money_box">
      <div class="money_title">
        <span>{{ $t("recharge.money_title2") }}</span>
        <span>{{ $t("recharge.money_text2") }}</span>
      </div>
      <div class="money_content">
        <div class="monty_ctt_box">
          <div>
            <span>30</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>850.00USD</span>
          </div>
          <div>
            <span>(28.33USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>60</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>1600.00USD</span>
          </div>
          <div>
            <span>(26.67USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>90</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>2250.00USD</span>
          </div>
          <div>
            <span>(25.00USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div>
    <div class="money_box">
      <div class="money_title">
        <span>{{ $t("recharge.money_title3") }}</span>
        <span>{{ $t("recharge.money_text3") }}</span>
      </div>
      <div class="money_content">
        <div class="monty_ctt_box">
          <div>
            <span>30</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>900.00USD</span>
          </div>
          <div>
            <span>(30.00USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>60</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>1700.00USD</span>
          </div>
          <div>
            <span>(28.33USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>90</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>2400.00USD</span>
          </div>
          <div>
            <span>(26.67USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div>
    <div class="money_box">
      <div class="money_title">
        <span>{{ $t("recharge.money_title4") }}</span>
        <span>{{ $t("recharge.money_text4") }}</span>
      </div>
      <div class="money_content">
        <div class="monty_ctt_box">
          <div>
            <span>30</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>900.00USD</span>
          </div>
          <div>
            <span>(30.00USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>60</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>1700.00USD</span>
          </div>
          <div>
            <span>(28.33USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>90</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>2400.00USD</span>
          </div>
          <div>
            <span>(26.67USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div>
    <div class="money_box">
      <div class="money_title">
        <span>{{ $t("recharge.money_title5") }}</span>
        <span>{{ $t("recharge.money_text5") }}</span>
      </div>
      <div class="money_content">
        <div class="monty_ctt_box">
          <div>
            <span>30</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>900.00USD</span>
          </div>
          <div>
            <span>(30.00USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>60</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>1700.00USD</span>
          </div>
          <div>
            <span>(28.33USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
        <div class="monty_ctt_box">
          <div>
            <span>90</span><span>{{ $t("recharge.sessions") }}</span>
          </div>
          <div>
            <span>2400.00USD</span>
          </div>
          <div>
            <span>(26.67USD/{{$t("index.pddate")==2?'S':'节'}})</span>
          </div>
          <div>
            <span @click="toRecharge()">{{ $t("recharge.Recharge") }}</span>
          </div>
          <img class="money_img" src="../../assets/img/money.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,ref } from "vue";
import { useRouter } from "vue-router";
import { getRecharge } from "@/api/getData";
import AppUserHeader from "./componentrecharge/AppUserHeader.vue";
import { ElMessageBox } from "element-plus";
export default {
  name: "VueStudentRecharge",
  components: { AppUserHeader },
  setup() {
    const obj = reactive({
      rechargeList: null,
    });

    const router = useRouter();

    /**
     * 跳转账单界面
     */
    const toRecharge = (index,iindex) => {
      let billing = {};
      let fenlei = obj.rechargeList[index];
      let ke = fenlei.course_list[iindex];
      billing.course_zh_name = fenlei.course_zh_name;
      billing.course_en_name = fenlei.course_en_name;
      billing.course_time = ke.sessions;
      billing.unit_price = ke.unit_price;
      billing.original_price = ke.amount;
      billing.actual_payment = ke.amount;
      billing.coupon_number = ke.amount;
      billing.raward_time = 0;
      localStorage.setItem("billing",JSON.stringify(billing));
      router.push({
        path: "/billing",
      });
    };

    /**
     * 初始化
     */
     const loading = ref(true);
    
    /**
     * 获取商品列表
     */
    const getList = async () => {
      let lang = localStorage.getItem("language");
      try {
        const res = await getRecharge();
        obj.rechargeList = res.data.data;
        loading.value = false;
      } catch (error) {
        ElMessageBox.alert(lang == 'en' ? 'Network abnormality, please refresh this webpage!' : '网络异常，请刷新本网页！', '', {
            confirmButtonText: 'OK',
        });
      }
    };

    onMounted(()=>{
      getList();
    })
    return { toRecharge, ...toRefs(obj),loading };
  },
};
</script>

<style lang="less" scoped>
.Recharge_box {
  width: 900px;
  margin: 0 auto;
  min-height: 1400px;
  box-sizing: border-box;
  padding-top: 45px;
  padding-bottom: 30px;

  .money_box {
    margin-top: 20px;
    width: 100%;
    position: relative;
    .my_xing {
      position: absolute;
      top: 50%;
      left: -60px;
      width: 30px;
      height: 30px;
    }
    .money_content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .monty_ctt_box {
        position: relative;
        width: 270px;
        height: 160px;
        justify-items: center;
        div {
          position: relative;
          z-index: 10;
          color: #fff;
          text-align: center;
        }
        div:nth-child(1) {
          height: 50px;
          line-height: 50px;
          font-size: 20px;
          span:nth-child(1) {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        div:nth-child(2) {
          height: 30px;
          line-height: 30px;
          font-size: 18px;
          margin-bottom: 5px;
          span {
            background-color: #4c98c5;
            border-radius: 5px;
            padding: 3px 8px;
          }
        }
        div:nth-child(3) {
          height: 20px;
          line-height: 30px;
          font-size: 16px;
          span {
            background-color: #4c98c5;
            border-radius: 5px;
            padding: 3px 5px;
            font-size: 14px;
          }
        }
        div:nth-child(4) {
          cursor: pointer;
          height: 50px;
          line-height: 65px;
          span {
            font-size: 20px;
            background-color: #ffc89f;
            border-radius: 10px;
            padding: 3px 3px;
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      // .monty_ctt_box:nth-child(3) {
      //   div:nth-child(2) {
      //     height: 30px;
      //     line-height: 30px;
      //     font-size: 17.4px;
      //     margin-bottom: 5px;
      //     span {
      //       background-color: #4c98c5;
      //       border-radius: 5px;
      //       padding: 3px 8px;
      //     }
      //   }
      // }
    }
    .money_title {
      width: 130%;
      height: 50px;
      line-height: 50px;

      word-wrap: break-word;
      word-break: normal;
      word-break: break-all;
      span:nth-child(1) {
        font-size: 22px;
        color: #ffcd9f;
        margin-right: 10px;
        word-wrap: break-word;
        word-break: normal;
        word-break: break-all;
      }
      span:nth-child(2) {
        font-size: 20px;
        color: #a6a6a6;
        word-wrap: break-word;
        word-break: normal;
        word-break: break-all;
      }
    }
  }

  .user_box {
    width: 100%;
    justify-content: space-between;
    display: flex;
    .user_you {
      width: 180px;
      height: 110px;
      background-color: #7580e5;
      color: #fff;
      text-align: center;
      line-height: 110px;
      border-radius: 10px;
      vertical-align: middle;
      img {
        width: 40px;
        height: 35px;
        margin-right: 20px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
</style>
