<template>
  <div class="app-schedule-nav clearfix">
    <Appyoutopnav />
    <div class="topbox">
      <Appscheduledate />
      <div class="sc_juz_box">
        <div class="title ellipsis">{{ $t("schedule.title") }}</div>
        <div class="CA_box">
          <div class="ca_b_list_box">
            <!-- <div class="ca_b_list">
              <div>24</div>
              <div>HSK {{ $t("schedule.Course") }} Level1 Lesson24</div>
              <div><img src="../../assets/img/tisone.png" alt="" /></div>
              <div>{{ $t("schedule.pddate") == "1" ? "9月16日" : "Sept.16" }}</div>
              <div>07:00-07:55</div>
              <div class="ca_b_yuan"></div>
              <div class="ca_b_xian"></div>
            </div>
            <div class="ca_b_list">
              <div>24</div>
              <div>HSK {{ $t("schedule.Course") }} Level1 Lesson24</div>
              <div><img src="../../assets/img/tisone.png" alt="" /></div>
              <div>{{ $t("schedule.pddate") == "1" ? "9月16日" : "Sept.16" }}</div>
              <div>07:00-07:55</div>
              <div class="ca_b_yuan1"></div>
              <div class="ca_b_xian"></div>
            </div>
            <div class="ca_b_list">
              <div>24</div>
              <div>HSK {{ $t("schedule.Course") }} Level1 Lesson24</div>
              <div><img src="../../assets/img/two.png" alt="" /></div>
              <div>{{ $t("schedule.pddate") == "1" ? "9月16日" : "Sept.16" }}</div>
              <div>07:00-07:55</div>
              <div class="ca_b_yuan1"></div>
              <div class="ca_b_xian"></div>
            </div>
            <div class="ca_b_list">
              <div>24</div>
              <div>HSK {{ $t("schedule.Course") }} Level1 Lesson24</div>
              <div><img src="../../assets/img/two.png" alt="" /></div>
              <div>{{ $t("schedule.pddate") == "1" ? "9月16日" : "Sept.16" }}</div>
              <div>07:00-07:55</div>
              <div class="ca_b_yuan1"></div>
              <div class="ca_b_xian"></div>
            </div> -->
          </div>
          <div class="ca_b_page" v-if="1 == 0">
            <i class="iconfont icon-xiangzuojiantou"></i>
            <span>{{ $t("schedule.pddate") == "1" ? "翻页" : "Turn" }}</span>
            <i class="iconfont icon-xiangyoujiantou"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Appyoutopnav from "./components/Appyoutopnav.vue";
import Appscheduledate from "./components/Appscheduledate.vue";
import { toRefs, reactive, onMounted, ref, h } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
export default {
  name: "VueAppschedule",
  components: {
    Appyoutopnav,
    Appscheduledate,
  },
  setup(props) {
    const store = useStore();
    let obj = reactive({
      userinfo: store.state.User.userinfo,
    });
    const calendar = ref();
    const selectDate = (val) => {
      calendar.value.selectDate(val);
    };
    return {
      ...toRefs(obj),
      selectDate,
      calendar,
    };
  },
};
</script>

<style lang="less" scoped>
.app-schedule-nav {
  box-sizing: border-box;
  padding-top: 45px;
  margin-left: 75px;
  width: 856px;
  .topbox {
    width: 100%;
    .sc_juz_box {
      width: 650px;
      margin: 0 auto;

      .CA_box {
        width: 650px;
        margin: 0 auto;
        box-sizing: border-box;
        font-size: 15px;
        color: #5f84c7;
        .ca_b_list_box {
          width: 100%;
          height: 201px;
        }
        .ca_b_page {
          span {
            cursor: pointer;
            margin: 0 10px;
          }
          i {
            cursor: pointer;
          }
        }
        .ca_b_list {
          display: flex;
          position: relative;
          font-size: 18px;
          height: 40px;
          margin-bottom: 11px;
          .ca_b_yuan {
            position: absolute;
            top: 5px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #5f84c7;
            border: 4.7px solid #5f84c7 !important;
          }
          .ca_b_yuan1 {
            position: absolute;
            top: 5px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-sizing: border-box;
            background: #fff;
            border: 2px solid #5f84c7 !important;
          }
          .ca_b_yuan1::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 2px;
            margin-left: -1px;
            margin-top: -1px;
            background: #5f84c7;
            border-radius: 50%;
          }
          .ca_b_xian {
            position: absolute;
            top: 18px;
            left: 4px;
            width: 1px;
            height: 33px;
            border-radius: 50%;
            box-sizing: border-box;
            background: #5f84c7;
            border: 1 solid #5f84c7;
          }
          div:nth-child(1) {
            flex: 1;
            text-align: center;
          }
          div:nth-child(2) {
            flex: 5;
          }
          div:nth-child(3) {
            flex: 1;
            img {
              width: 40px;
              height: 40px;
              margin-top: -10px;
            }
          }
          div:nth-child(4) {
            flex: 1.4;
            text-align: right;
          }
          div:nth-child(5) {
            flex: 2;
            text-align: right;
          }
        }
        .ca_b_list:nth-child(4) {
          .ca_b_xian {
            border: none;
            background: none;
          }
        }
      }

      .title {
        width: 100%;
        height: 46px;
        color: #5f84c7;
        font-size: 24px;
        margin-top: 27px;
      }
    }
  }
}
</style>
