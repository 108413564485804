import { createI18n } from 'vue-i18n';
const locale = "zh";

const i18n = createI18n({
    legacy: false, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale: localStorage.getItem('language') || 'zh',
    messages: {
        'zh': {
            index: {
                title: '你好，vue-i18n',
                pddate:"1",
                USD:"其他币种",
                RMB:"人民币",
                SGD:"新加坡元",
                Student:"学生"
            },
            apptopnav:{
                Contact_Us:"联系我们",
            },
            appheader:{
                Course:"课程",
                Recharge:"购买",
                Discount:"推荐有礼",
                Contract:"服务合同",
            },
            appleft:{
                AddSubAccount:"\xa0\xa0请添加学生\xa0\xa0",
                SelectSubAccount:"\xa0\xa0请选择学生\xa0\xa0",
                Homepage:`主\xa0\xa0\xa0\xa0\xa0\xa0\xa0页`,
                Appointment:"约\xa0\xa0\xa0\xa0\xa0\xa0\xa0课",
                Schedule:"课\xa0\xa0程\xa0\xa0表",
                Course_Record:"历史课程",
                Messages:"消息记录",
                code_text:"添加客服获取免费试听",
            },
            homepage:{
                title:"拓展阅读：",
                title1:"用户反馈及建议：",
                textarea:"      知行中文十分重视您的学习体验，若您有任何关于课程学习和软件使用方面的建议，请及时跟我们留言，我们将在第一时间对您的留言进行回复！",
                zuo_box:"知行中文教学系统用户指引——",
                yuo_box:"知行中文课程管理系统用户指引——",
                title2:"总购买课时",
                title3:"已学习课时",
                title4:"剩余课时",
                s:"节"
            },
            intment:{
                top_button1:"\xa0\xa0\xa0约\xa0\xa0\xa0课\xa0\xa0\xa0",
                top_button2:"\xa0\xa0\xa0取\xa0\xa0\xa0消\xa0\xa0\xa0",
                top_button3:"\xa0\xa0\xa0加\xa0\xa0\xa0课\xa0\xa0\xa0",
                this_week:"返回本周",
                Single:"取消单次课时",
                Cycle:"取消循环课时",
                dateType1:"北京时间",
                dateType2:"新加坡时间",
                dateType3:"温哥华时间",
                dateType4:"伦敦时间",
                dateType5:"巴黎时间",
                dateType6:"纽约时间",
                dateType7:"东京时间",
                dateType8:"悉尼时间",
                weeks1:"时长",
                weeks2:"星期一",
                weeks3:"星期二",
                weeks4:"星期三",
                weeks5:"星期四",
                weeks6:"星期五",
                weeks7:"星期六",
                weeks8:"星期日",
                tishi:`您所预约课程时间在4周后与其他学生冲突，请核对老师时间表，调整约课时间。或联系客服人员协助。`
            },
            schedule:{
                title:"课程提醒",
                Course:"中文课",
                pddate:"1"
            },
            course:{
                cs_tit_bot1:"\xa0\xa0\xa0课\xa0程\xa0记\xa0录\xa0\xa0\xa0",
                cs_tit_bot2:"\xa0\xa0\xa0约\xa0课\xa0记\xa0录\xa0\xa0\xa0",
                cs_tit_bot3:"\xa0\xa0\xa0取\xa0消\xa0记\xa0录\xa0\xa0\xa0",
                cs_tit_bot4:"\xa0\xa0\xa0加\xa0课\xa0记\xa0录\xa0\xa0\xa0",
                pddate:"1"
            },
            message:{
                title:"消息记录：",
                pddate:"1"
            },
            recharge:{
                excl:"专属代金券",
                Coupons:"优惠券",
                Learned:"已学习",
                Remaining:"当前学生剩余",
                s:"课时",
                sessions:"课时",
                Recharge:"\xa0\xa0\xa0购买\xa0\xa0\xa0",
                money_title1:"青少年中文1V1",
                money_title2:"HSK水平考试1V1",
                money_title3:"AP中文考试1V1",
                money_title4:"IB中文考试1V1",
                money_title5:"SG同步课程",
                money_text1:"--适合7-15岁青少年，对标中国小学语文课程；单节课时55分钟；",
                money_text2:"--适合10岁以上，有中文考级、系统学习中文需求的学生；单节课时55分钟；",
                money_text3:"--适合14岁以上，有AP中文考试需求的学生；单节课时55分钟；",
                money_text4:"--适合14岁以上，有IB中文考试需求的学生；单节课时55分钟；",
                money_text5:"--适合新加坡地区中小学学生，与MOE大纲同步；单节课时55分钟；",
                Available:"可使用",
                Used:"已使用",
                Code:"优惠码",
                Gift:"推荐有礼",
                GiftText:"每购买30课时赠送2课时，每个学生账号限用1次",
                Members:"星级会员",
                MembersText:"总课时费享受8.0折优惠，此学生账号永久有效",
                Course:"水平考试",
                Check:"查看详情",
                No:"无可使用优惠码",
                ADD:"添加",
            },
            Billing:{
                Excl:"专属代金券",
                zheKou:"折",
                No_Use:"不使用",
                billing:"账单信息",
                Payment:"支付币种",
                Student:"学生",
                Type:"课程名称",
                Purchase:"购买课时",
                Complimentary:"赠送课时",
                Total_Price:"课程总价",
                Unit_Price:"课程单价",
                Coupon:"优惠券",
                Members:"星级会员：8.0折",
                Discount:"优惠总价",
                Total_Sessions:"总课时数",
                Total_Price_Paid:"实付总价",
                Members1:"星级会员",
                INV_Gift:"推荐有礼",
                selected:"尚未选择优惠券",
                Submit:"提交订单",
            },
            Cashier:{
                cashier:"收银台",
                Counting_Down:"剩余支付时间：",
                Overdue:"超时未支付订单将会自动取消",
                Order_no:"订单编号",
                Total_Sessions:"课时总数",
                Actual:"实付金额",
                Payment_Amount:"支付金额",
                Bank_card:'银行卡',
                Alipay:"支付宝",
                WeChat:"微信",
                Step:"下一步",
                I:"我已阅读并同意",
                Terms:"条款和条件",
                and:"与",
                Privacy:"隐私政策",
                Order_no1:"卡号",
                my:"月/年",
                Immediately:"立即支付",
                method:"更改支付方式",
                Alipaytitle:"使用支付宝扫码支付",
                code:"扫二维码进行支付，有效期2小时",
                WeChattitle:"使用微信扫码支付"
            },
            discount:{
                title:"邀请好友，立享多重优惠！",
                Gift:"优惠1——推荐有礼",
                Members:"优惠2——星级会员",
                text1:`
                <p>推荐新人成功购课，推荐人获得4节赠送课时，被推荐人</p>
                <p>获得2节赠送课时（课程种类不限）；<p>
                `,
                text2:`
                <p>成功推荐10名新学员，升级为知行一星会员，该账户购</p>
                <p>课享受9.0折优惠； <i class="iconfont icon-jiaxingshoucangtianchong"></i><p>
                `,
                text3:
                `<p>
                成功推荐20名新学员，升级为知行二星会员，该账户购
                </p>
                <p>
                课享受8.5折优惠；
                <i class="iconfont icon-jiaxingshoucangtianchong">
                </i><i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <p>`,
                text4:
                `<p>
                成功推荐30名新学员，升级为知行三星会员，该账户购
                </p>
                <p>
                课享受8.0折优惠；
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <p>`,
                text5:"会员特权不限次数，不限时间，不限课种，永久有效！",
                text6:"敲重点：“推荐有礼”与“星级会员”优惠可叠加使用。",
                title1:"1、扫码下载海报",
                title2:"2、分享到社交平台",
                text7:"使用手机扫描二维码",
                text8:"打开网页、下载海报",
                text9:"将海报分享至社交媒体",
                title3:"推荐成就",
                text10:"您已成功推荐",
                text101:"",
                text11:"人",
                text12:"您的会员等级",
                text13:"星",
                title4:"推荐记录",
                text14:"朋友账号",
                text15:"课程种类",
                text16:"购课日期",
                text17:"我的奖励",
            },
            contract:{
                contract:"合同文本",
                text1:"乙方签名：",
                text2:"电话：",
                text3:"国家及地区：",
                text4:"附件一：教学系统用户指南",
            },
            user:{
                title:"个人中心",
                text1:"邮箱",
                text2:"手机号码",
                text3:"微信",
                text4:"所在国家",
                save:"保存",
                user1:"学生姓名",
                user2:"性别",
                user3:"男",
                user4:"女",
                user5:"出生日期",
                user6:"在校年级",
                user7:"大概识字量",
                user8:"大概写字量",
                user9:"母语",
                user10:"是否学过拼音",
                user11:"家庭中文环境",
                user12:"中文学习经历",
                user13:"家长期望和备注",
                user14:"展开详细信息",
                user15:"收起",
            },
            order:{
                title:"我的订单",
                title1:"订单详情",
                title2:"当前状态：",
                user1:"学生",
                user2:"购买产品",
                user3:"奖励课时",
                user4:"购买课时",
                user5:"使用优惠券",
                user6:"课程总价",
                user7:"实付总价",
                user8:"订单编号",
                user9:"下单时间",
                user10:"支付时间",
                user11:"课时",
                user12:"推荐有礼",
                user13:"星级会员",
                user14:"取消时间",
            },
            login:{
                title:"登录",
                title1:"欢迎您!",
                title2:"修改密码",
                oldpwd:"输入原密码",
                newpwd:"输入新密码",
                thenewpwd:"重复新密码",
                button1:"取消",
                button2:"确定",
                username:"请输入用户名",
                thepwd:"请输入密码",
                Register:"注册",
                address:"请输入邮箱地址",
                pwdaga:"请再次输入密码",
                Access1:"即使身处全球",
                Access2:"也能获得最优质的汉语环境！",
                button3:"现在登录",
                button4:"现在注册",
                button5:"返回登录",
                button6:"没账号，去注册"
            }
        },
        'en': {
            login:{
                title:"Login",
                title1:"Welcome!",
                title2:"Change Password",
                oldpwd:"Enter the old password",
                newpwd:"Enter a new password",
                thenewpwd:"Repeat the new password",
                button1:"Cancel",
                button2:"Confirm",
                username:"Please enter the user name",
                thepwd:"Please enter the password",
                Register:"Register",
                address:"Please enter the email address",
                pwdaga:"Please enter the password again",
                Access1:"Access to the First-Rate Chinese",
                Access2:"Teaching Resources Around the World!",
                button3:"Login Now",
                button4:"Register Now",
                button5:"Back to Login",
                button6:"Go to Register"
            },
            order:{
                title:"My Orders",
                title1:"Order Details",
                title2:"Current Status",
                user1:"Student",
                user2:"Course Type",
                user3:"Purchase Sections",
                user4:"Complimentary Sections",
                user5:"Use Coupons",
                user6:"Total Price",
                user7:"Total Price Paid",
                user8:"Order No.",
                user9:"Order Time",
                user10:"Payment Time",
                user11:"Sessions",
                user12:"Invitation Gift",
                user13:"Members",
                user14:"Cancellation Time",
            },
            user:{
                title:"Personal center",
                text1:"Email",
                text2:"Cellphone",
                text3:"WeChat",
                text4:"Country",
                save:"save",
                user1:"Name",
                user2:"Set",
                user3:"M",
                user4:"F",
                user5:"Birthdate",
                user6:"Grade in school",
                user7:"Approximate literacy",
                user8:"Approximate word volume",
                user9:"Native language",
                user10:"Learned Pinyin or not",
                user11:"Chinese environment at home",
                user12:"Chinese learning experience",
                user13:"Parent expectations and remarks",
                user14:"Detailes",
                user15:"Put away",
            },
            contract:{
                contract:"Contract text",
                text1:"Party B Signature:",
                text2:"Cell phone:",
                text3:"Country and Region:",
                text4:"Annex:Teaching System User Guide",
            },
            discount:{
                title:"Invite friends and enjoy multiple offers!",
                Gift:"Invitation Gift",
                Members:"Star Members",
                text1:`
                <p>The inviter will get 4 complimentary sessions，and invitee</p>
                <p>will get 2 free sessions (no limitation of the course types).</p>
                `,
                text2:`
                <p>Invite 10 students will be upgraded to a one-star membership,</p>
                <p>enjoy a 10% discount.
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                </p>
                `,
                text3:`
                <p>Invite 20 students will be upgraded to a two-star membership,</p>
                <p>enjoy a 15% discount.
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                </p>
                `,
                text4:`
                <p>Invite 30 students will be upgraded to a three-star membership,</p>
                <p>enjoy a 20% discount.
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                <i class="iconfont icon-jiaxingshoucangtianchong"></i>
                </p>
                `,
                text5:"Unlimited in number, time or course types; Valid forever!",
                text6:"Key points: Above two deals can be used at the same time.",
                title1:"1. Scan the code to download poster",
                title2:"2. Share to social platform",
                text7:"Scan the QR code",
                text8:"Download the poster",
                text9:"Share to social platform",
                title3:"Invitation Achievement",
                text10:"Invitation",
                text101:"Successful",
                text11:"p",
                text12:"Member Star",
                text13:"s",
                title4:"Invitation Record",
                text14:"Friend Account",
                text15:"Course Types",
                text16:"Purchase Date",
                text17:"My Rewards",
            },
            Cashier:{
                Overdue:" Overdue orders will be automatically",
                cashier:"Cashier",
                Counting_Down:"Counting Down:",
                Order_no:"Order No.",
                Total_Sessions:"Total Sessions",
                Actual:"Actual Amount Paid",
                Payment_Amount:"Payment Amount",
                Bank_card:'Bank card',
                Alipay:"Alipay",
                WeChat:"WeChat",
                Step:"Next Step",
                I:"I have read and agree to the",
                Terms:"Terms And Conditions",
                and:"and",
                Privacy:"Privacy Policy",
                Order_no1:"Card No.",
                my:"M/Y",
                Immediately:"Pay Immediately",
                method:"Change of payment method",
                Alipaytitle:"Use Alipay QR code to pay",
                code:"Scan QR code for payment, valid for 1 hour",
                WeChattitle:"Use WeChat QR code to pay",
            },
            Billing:{
                Excl:"Exclusive discount",
                zheKou:"discount",
                Submit:"Submit",
                selected:"No coupon selected",
                Members1:"Members",
                INV_Gift:"INV Gift",
                billing:"Billing Information",
                Payment:"Payment Currency",
                Student:"Student",
                Type:"Course Type",
                Purchase:"Purchase Sessions",
                Complimentary:"Complimentary Sessions",
                Total_Price:"Total Price",
                Unit_Price:"Unit Price",
                Coupon:"Coupon",
                Members:"Members: 20% off",
                Discount:"Total Discount Price",
                Total_Sessions:"Total Sessions",
                Total_Price_Paid:"Total Price Paid",
                
            },
            recharge:{
                excl:"Exclusive discount",
                Gift:"Invitation Gift",
                No:"No Coupon Available",
                Available:"Available",
                Check:"Check",
                Course:"Course",
                GiftText:"2 free sessions for every 30 purchase, once only",
                Members:"Members",
                MembersText:"Star members: 10% off, valid forever",
                Code:"Coupon Code",
                ADD:"ADD",
                Used:"Used",
                Coupons:"Coupons",
                Learned:"Learned",
                Remaining:"Remaining",
                s:"s",
                sessions:"sessions",
                Recharge:"\xa0\xa0Recharge\xa0\xa0",
                money_title1:"Juvenile Course 1V1",
                money_title2:"HSK Standard Course 1V1",
                money_title3:"AP Course 1V1",
                money_title4:"IB Course 1V1",
                money_title5:"SG Course",
                money_text1:"--For 7-15 students, corresponding to domestic curriculums; 55 mins/section;",
                money_text2:"--For students over 10 with systematic study requirements; 55 mins/section;",
                money_text3:"--For students over 14 with AP Chinese Exam requirements; 55 mins/section;",
                money_text4:"--For students over 14 with IB Chinese Exam requirements; 55 mins/section;",
                money_text5:"--For primary and secondary  students in Singapore, synchronised with the MOE curriculum.; 55 mins/section;",
            },
            message:{
                title:"Messages Record：",
                pddate:"2"
            },
            course:{
                cs_tit_bot1:"Course Record",
                cs_tit_bot2:"Appointment",
                cs_tit_bot3:"Cancellation",
                cs_tit_bot4:"Increasement",
                pddate:"2"
            },
            schedule:{
                title:"Course Alerts",
                Course:"Course",
                pddate:"2"
            },
            index: {
                title: 'Hello, Vue-i18n',
                pddate:"2",
                USD:"OtherCurrencies",
                RMB:"RMB",
                SGD:"SGD",
                Student:"Student"
            },
            apptopnav:{
                Contact_Us:"Contact Us",
            },
            appheader:{
                Course:"Course",
                Recharge:"Recharge",
                Discount:"Discount",
                Contract:"Contract",
            },
            appleft:{
                AddSubAccount:"Add Student",
                SelectSubAccount:"Select Student",
                Homepage:"Homepage",
                Appointment:"Appointment",
                Schedule:"Schedule",
                Course_Record:"Course Record",
                Messages:"Messages",
                code_text:"Add Customer Service to Get a Free Trial",
            },
            homepage:{
                title:"Extended Reading",
                title1:"Feedback and Suggestion",
                textarea:"      ZhiXing attache great importance to your learning experience. If you have any suggestions on course learning and software use, please leave us a message and we will reply to your message in time!",
                zuo_box:"Teaching System User Guide——",
                yuo_box:"Management System User Guide——",
                title2:"Total Sessions",
                title3:"Learned Sessions",
                title4:"Remaining Sessions",
                s:"s"
            },
            intment:{
                top_button1:"Appointment",
                top_button2:"Cancellation",
                top_button3:"Increasement",
                this_week:"This Week",
                Single:"Cancel Single",
                Cycle:"Cancel Cycle",
                dateType1:"Beijing",
                dateType2:"Singapore",
                dateType3:"Vancouver",
                dateType4:"London",
                dateType5:"Paris",
                dateType6:"New York",
                dateType7:"Tokyo",
                dateType8:"Sydney",
                weeks1:"Lenbth",
                weeks2:"MON",
                weeks3:"TUE",
                weeks4:"WED",
                weeks5:"THU",
                weeks6:"FRI",
                weeks7:"SAT",
                weeks8:"SUN",
                tishi:`Your appointment conflict with other students after 4 weeks, please check the
                teacher's schedule and adjust the appointment time. Or contact customer service
                for assistance.`
            }
        }
    }
})

export default i18n 