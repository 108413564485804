<template>
  <div class="app-zho-nav">
    <div class="zuo-box ellipsis" >
      {{ $t("homepage.zuo_box") }} <i class="iconfont icon-xiazai"></i>
    </div>
    <div class="you-box ellipsis">
      {{ $t("homepage.zuo_box") }} <i class="iconfont icon-xiazai"></i>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "VueApptopnav",
  setup(props) {
    const store = useStore();

    const obj = reactive({
      userinfo: store.state.User.userinfo,
    });
    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.app-zho-nav {
  box-sizing: border-box;
  padding-top: 50px;
  width: 856px;
  display: flex;
  font-size: 22px;
  .zuo-box{
    flex:1;
    color:#5F84C7;
    cursor: pointer;
    i{
      font-size: 22px;
    }
  }
  .you-box{
    flex:1;
    text-align: right;
    color:#5F84C7;
    cursor: pointer;
    i{
      font-size: 22px;
    }
  }
}
</style>
