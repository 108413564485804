<template>
  <div class="app-left-nav">
    <!-- 用户头 begin -->
    <div class="user_box">
      <div v-if="current_student == null" class="user">
        <img @click.prevent="goToUser" src="../../../assets/img/admin.png" alt="" />
        <div v-if="$t('index.pddate') == 2" class="zhuzhanghaobox">
          <!-- {{
            userinfo.username.toString().indexOf("@") != -1
              ? userinfo.username.split("@")[0]
              : "admin"
          }} -->
          <span v-if="student_length != 0" @click="showListClick">{{
            $t("appleft.SelectSubAccount")
          }}</span>
          <span v-if="student_length == 0" @click.prevent="goToUser">{{
            $t("appleft.AddSubAccount")
          }}</span>
        </div>
        <div v-else class="zwzhuzhanghaobox">
          <!-- {{
            userinfo.username.toString().indexOf("@") != -1
              ? userinfo.username.split("@")[0]
              : "admin"
          }} -->
          <span v-if="student_length != 0" @click="showListClick">{{
            $t("appleft.SelectSubAccount")
          }}</span>
          <span v-if="student_length == 0" @click.prevent="goToUser">{{
            $t("appleft.AddSubAccount")
          }}</span>
        </div>
      </div>
      <div v-else class="user" @click="showListClick">
        <img v-if="current_student.header_img" :src="current_student.header_img" alt="" />
        <img v-else src="../../../assets/img/tou.png" alt="" />
        <div>
          {{ current_student.nickname }}
        </div>
      </div>
      <div class="you" @click="showListClick">
        <i class="iconfont icon-xiangyou3" :style="xuanzhuan"></i>
      </div>
      <div :class="showList">
        <div class="user_li_box">
          <div class="user_li"></div>
          <!-- <div class="user_li" @click="showUserClick(404)">
            <img src="../../../assets/img/tou.png" alt="" />
            <div>
              {{
                userinfo.username.toString().indexOf("@") != -1
                  ? userinfo.username.split("@")[0]
                  : "admin"
              }}
            </div>
          </div> -->
          <div
            v-for="(item, index) in student_list"
            class="user_li"
            @click="showUserClick(index)"
          >
            <img v-if="item.header_img" :src="item.header_img" alt="" />
            <img v-else src="../../../assets/img/tou.png" alt="" />
            <div>{{ item.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户头 end -->

    <!-- 分类 begin -->
    <div class="type-box">
      <ul :class="$t('index.pddate') == 2 ? '' : 'chineseZhiTi'">
        <li :class="typeclick[0].myli" @click="xzclick(0)">
          <i class="iconfont icon-zhuye"></i>
          <span>{{ $t("appleft.Homepage") }}</span>
        </li>
        <li :class="typeclick[1].myli" @click="xzclick(1)">
          <i class="iconfont icon-yuekeguanli"></i>
          <span>{{ $t("appleft.Appointment") }}</span>
        </li>
        <li :class="typeclick[2].myli" @click="xzclick(2)">
          <i class="iconfont icon-kechengbiao"></i>
          <span>{{ $t("appleft.Schedule") }}</span>
        </li>
        <li :class="typeclick[3].myli" @click="xzclick(3)">
          <i class="iconfont icon-lishi"></i>
          <span>{{ $t("appleft.Course_Record") }}</span>
        </li>
        <li :class="typeclick[4].myli" @click="xzclick(4)">
          <i class="iconfont icon-xiaoxi"></i>
          <span>{{ $t("appleft.Messages") }}</span>
        </li>
      </ul>
    </div>
    <!-- 分类 eng -->

    <!-- 二维码 begin -->
    <div class="code_box">
      <div class="code_zw"></div>
      <div class="code_Frame">
        <div class="code_bai"><img src="../../../assets/img/Frame.png" alt="" /></div>
      </div>
      <div class="code_text">{{ $t("appleft.code_text") }}</div>
      <img src="../../../assets/img/wx.jpg" alt="" />
      <div class="code_wechat">（WeChat）</div>
    </div>
    <!-- 二维码 eng -->
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import 'swiper/css/navigation';
import "swiper/css/pagination";

// import './style.css';

// import required modules
import { Navigation, Pagination } from "swiper";
import { ElMessage } from "element-plus";
import { getHuilv } from "@/api/getWbData";
export default {
  name: "VueApptopnav",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const obj = reactive({
      showList: "user_list",
      userinfo: store.state.User.userinfo,
      xuanzhuan: "",
      typeclick: [
        {
          myli: "typeclick",
          num: 0,
        },
        {
          myli: "",
          num: 1,
        },
        {
          myli: "",
          num: 2,
        },
        {
          myli: "",
          num: 3,
        },
        {
          myli: "",
          num: 4,
        },
      ],
      current_student: null,
      student_list: null,
      student_length: 0,
    });

    const showListClick = () => {
      obj.showList = obj.showList == "user_list" ? "user_list showList" : "user_list";
      obj.xuanzhuan = obj.xuanzhuan == "" ? "transform: rotateY(180deg);" : "";
    };

    const showUserClick = (index) => {
      obj.showList = obj.showList == "user_list" ? "user_list showList" : "user_list";
      obj.xuanzhuan = obj.xuanzhuan == "" ? "transform: rotateY(180deg);" : "";
      if (index == 404) {
        obj.current_student = null;
        localStorage.removeItem("current_student");
        store.commit("SET_COURRENT_STUDENT", null);

        return false;
      }
      obj.current_student = obj.student_list[index];
      store.commit("SET_COURRENT_STUDENT", obj.student_list[index]);
      localStorage.setItem("current_student", JSON.stringify(obj.student_list[index]));
      let str = localStorage.getItem("language");
      ElMessage({
        message: str == "en" ? "Successfully switched account!" : "切换账号成功！",
        type: "success",
      });
    };

    onMounted(() => {
      store.commit("updateName", localStorage.getItem("emil"));
      let type = obj.typeclick;
      for (let i = 0; i < type.length; i++) {
        type[i].myli = "";
      }
      let index = localStorage.getItem("myliIndex");
      if (index == null) {
        index = 0;
      }
      type[index].myli = "typeclick";
      xzclick(index);
      obj.current_student =
        localStorage.getItem("current_student") == undefined
          ? null
          : JSON.parse(localStorage.getItem("current_student"));
      obj.student_list = JSON.parse(localStorage.getItem("student_list"));
      obj.student_length = Object.keys(obj.student_list).length;
    });
    const xzclick = (index) => {
      let type = obj.typeclick;
      for (let i = 0; i < type.length; i++) {
        type[i].myli = "";
      }
      type[index].myli = "typeclick";
      localStorage.setItem("myliIndex", index);
      switch (index) {
        case 0:
          router.push("/");
          break;
        case 1:
          router.push("/intment");
          break;
        case 2:
          router.push("/schedule");
          break;
        case 3:
          router.push("/courec");
          break;
        case 4:
          router.push("/message");
          break;

        default:
          break;
      }
    };

    const goToUser = () => {
      router.push({
        path: "/user",
      });
    };
    return {
      ...toRefs(obj),
      xzclick,
      showListClick,
      showUserClick,
      modules: [Navigation, Pagination],
      goToUser,
    };
  },
};
</script>

<style lang="less" scoped>
.app-left-nav {
  width: 230px;
  min-width: 230px;
  z-index: 20;
  // 左侧底部 二维码
  .code_box {
    margin-top: 73px;
    margin-bottom: 50px;
    width: 230px;
    height: 226px;
    border-radius: 31.92px;
    background: rgba(247, 247, 247, 1);
    text-align: center;
    box-sizing: border-box;
    padding: 10px 10px;
    font-size: 15px;
    position: relative;
    .code_text {
      font-size: 18px;
      margin-bottom: 5px;
      position: relative;
      z-index: 20;
    }
    img {
      width: 111px;
      height: 118px;
    }
    .code_Frame {
      position: absolute;
      top: -45px;
      left: 50%;
      width: 80px;
      height: 80px;
      margin-left: -40px;
      border-radius: 50%;
      background: rgba(247, 247, 247, 1);
      .code_bai {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        margin-left: -30px;
        border-radius: 50%;
        background-color: #fff;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30px;
          height: 30px;
          margin-top: -17px;
          margin-left: -15px;
        }
      }
    }
    .code_wechat {
      font-size: 16px;
      color: rgba(166, 166, 166, 1);
    }
    .code_zw {
      width: 100%;
      height: 20px;
    }
  }

  // 左侧用户信息 begin
  .user_box {
    width: 100%;
    display: flex;
    text-align: center;
    box-sizing: border-box;
    color: rgba(95, 132, 199, 1);
    font-size: 10px;
    position: relative;
    background-color: #fff;
    .zhuzhanghaobox {
      width: 100%;
      span {
        // background: rgba(95, 132, 199, 1);
        // color: #fff;
        font-size: 20px;
        word-wrap: break-word;
        word-break: normal;
        word-break: break-all;
        // padding: 0 8px 3px 8px;
        border-radius: 5px;
      }
    }
    .zwzhuzhanghaobox {
      width: 100%;
      span {
        // background: rgba(95, 132, 199, 1);
        // color: #fff;
        font-size: 20px;
        word-wrap: break-word;
        letter-spacing: 2px;
        word-break: normal;
        word-break: break-all;
        // padding: 0 8px 3px 8px;
        border-radius: 5px;
      }
    }
    .showList {
      animation-duration: 0.35s;
      animation-name: slidein;
      animation-fill-mode: forwards;
      animation-direction: alternate;
    }
    .xuanzhuan {
      transform: rotateY(180deg);
    }
    .xuanzhuan2 {
      transform: rotateY(-180deg);
    }
    @keyframes slidein {
      from {
        left: -500px;
        width: 0;
        opacity: 0;
      }

      to {
        left: 0;
        width: auto;
        opacity: 1;
      }
    }
    .user_list {
      transition: 0.35s;
      width: 0px;
      height: 170px;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 7px #ccc;
      overflow: hidden;
      z-index: 10;
      position: absolute;
      left: -500px;
      top: 30px;
      opacity: 1;
      .user_li_box {
        height: 100%;
        display: flex;
        .user_li {
          display: inline-block;
          margin: 0 10px;
          cursor: pointer;
          box-sizing: border-box;
          padding-top: 15px;
          width: 170px;
          height: 90%;
          img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
          }
        }
      }
    }
    .user {
      height: 240px;
      flex: 3;
      font-size: 13px;
      position: relative;
      box-sizing: border-box;
      padding-top: 45px;
      // padding-left: 30px;
      font-size: 20px;
      z-index: 20;
      cursor: pointer;
      img {
        width: 115px;
        height: 115px;
        border-radius: 50%;
      }
    }
    .you {
      cursor: pointer;
      flex: 1;
      position: relative;
      z-index: 20;
      i {
        position: absolute;
        top: 90px;
        left: -10px;
        font-size: 32px;
        color: #c4c4c4;
        transition: 0.35s;
      }
    }
  }
  // 左侧用户信息 end

  // 左侧菜单栏 begin
  .type-box {
    width: 100%;

    ul {
      background: rgba(247, 247, 247, 1);
      width: 100%;
      height: 435px;
      padding: 10px 7.5px 0px 7.5px;
      box-sizing: border-box;
      border-radius: 20px;
      margin: 0;
      li {
        cursor: pointer;
        line-height: 65px;
        background: rgba(95, 132, 199, 1);
        border-radius: 5px;
        color: #fff;
        display: flex;
        margin-bottom: 20px;
        text-align: justify;
        i {
          flex: 1;
          font-size: 30px;
          margin-right: 5px;
          text-align: right;
        }
        span {
          font-size: 20px;
          text-align: center;
          flex: 3;
        }
      }
      .typeclick {
        border-radius: 5px;
        background: rgba(117, 128, 229, 1);
      }
      li:hover {
        border-radius: 5px;
        background: rgba(117, 128, 229, 1);
      }
    }

    // 中文 样式
    .chineseZhiTi {
      li {
        span {
          font-size: 22px !important;
        }
      }
      li:nth-child(4),
      li:nth-child(5) {
        span {
          letter-spacing: 1px;
        }
      }
    }
  }
  // 左侧菜单栏 end
}
/deep/.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none;
}
</style>
