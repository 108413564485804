<template>
  <div class="app-top-nav">
    <div class="w footer">
      <div class="zuobox">
        <img src="../../../assets/img/f.png" alt="" />
        <img src="../../../assets/img/t.png" alt="" />
        <img src="../../../assets/img/d.png" alt="" />
        <img src="../../../assets/img/z.png" alt="" />
        <img src="../../../assets/img/xhs.png" alt="" />
      </div>
      <div class="zhobox">
       Copyright ©2022-2024 zhixing chinese. All rights reserved. </div>
      <div class="youbox">
        <a href="https://victor-1024-shop.oss-cn-beijing.aliyuncs.com/user/2023/05/29/PrivacyPolicy.pdf" content-type="inline" target="_blank">Privacy Policy</a>
        <a href="https://victor-1024-shop.oss-cn-beijing.aliyuncs.com/user/2023/05/29/TermsAndConditions.pdf"  content-type="inline" target="_blank">Terms & Conditions</a>
      </div>
      <!-- <div class="youbox">
        <a href="https://victor-1024-shop.oss-cn-beijing.aliyuncs.com/user/2023/05/29/newPP.pdf" content-type="inline" target="_blank">Privacy Policy</a>
        <a href="https://victor-1024-shop.oss-cn-beijing.aliyuncs.com/user/2023/05/29/newTC.pdf"  content-type="inline" target="_blank">Terms & Conditions</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "VueApptopnav",
  setup(props) {
    const store = useStore();

    const obj = reactive({
      userinfo: store.state.User.userinfo,
    });
    // window.open();
    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.app-top-nav {
  width: 100%;
    height: 80px;
  background-image: linear-gradient(
    180deg,
    rgba(174, 180, 230, 1) 0%,
    rgba(95, 132, 199, 1) 100%
  );
  .footer {
    display: flex;
  }
  color: #fff;
  overflow: hidden;
  .zuobox {
    flex:0.7;
    width: 240px;
    height: 80px;
    box-sizing: border-box;
    padding: 20px 0;
    display: inline-block;
    img {
      width: 43px;
      box-sizing: border-box;
      height: 43px;
      margin-right: 10px;
    }
  }
  .zhobox {
    flex: 2;
    display: inline-block;
    margin: 0 auto;
    line-height: 80px;
    text-align: center;
    font-size:18px;
  }
  .youbox {
    flex: 0.9;
    display: inline-block;
    margin: 0 auto;
    font-size:18px;
  }
  .youbox{
    line-height: 80px;
    text-align:right;
    font-size:18px;
    a{
      padding:0 10px;
      color:#FFF;
    }
    a:nth-child(1){
      color:#FFF;
      border-right: 1px solid rgba(95, 100, 99, 1);
      // margin: 0 5px;
    }
  }
}
</style>
