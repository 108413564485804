<template>
  <div class="app-top-nav">
    <div class="w">
      <div @click="xzclick(0)" class="logo-img">
        <img src="../../../assets/logo.png" alt="" />
      </div>
      <div class="type-box">
        <div :class="typeclick[0].myli" @click="xzclick(0)">
          <img src="../../../assets/img/course.png" /> <span>{{$t("appheader.Course")}}</span>
        </div>
        <div :class="typeclick[1].myli" @click="xzclick(1)">
          <img src="../../../assets/img/rec.png" /> <span>{{$t("appheader.Recharge")}}</span>
        </div>
        <div :class="typeclick[2].myli" @click="xzclick(2)">
          <img src="../../../assets/img/dis.png" /> <span>{{$t("appheader.Discount")}}</span>
        </div>
        <div :class="typeclick[3].myli" @click="xzclick(3)">
          <img src="../../../assets/img/con.png" /> <span>{{$t("appheader.Contract")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "VueApptopnav",
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const obj = reactive({
      userinfo: store.state.User.userinfo,
      typeclick: [
        {
          myli: "xuanzho",
          num: 0,
        },
        {
          myli: "",
          num: 1,
        },
        {
          myli: "",
          num: 2,
        },
        {
          myli: "",
          num: 3,
        },
        {
          myli: "",
          num: 4,
        },
      ],
    });

    onMounted(() => {
      let type = obj.typeclick;
      for (let i = 0; i < type.length; i++) {
        type[i].myli = "";
      }
      let index = localStorage.getItem("myHeadIndex");
      if (index == null) {
        index = 0;
      }
      type[index].myli = "xuanzho";
      xzclick(index);
    });
    const xzclick = (index) => {
      let type = obj.typeclick;
      for (let i = 0; i < type.length; i++) {
        type[i].myli = "";
      }
      type[index].myli = "xuanzho";
      localStorage.setItem("myHeadIndex", index);
      switch (index) {
        case 0:
          router.push("/");
          localStorage.setItem("myliIndex", 0);
          break;
        case 1:
          router.push("/recharge");
          break;
        case 2:
          router.push("/discount");
          break;
        case 3:
          router.push("/contract");
          break;
        case 4:
          router.push("/message");
          break;

        default:
          break;
      }
    };

    return {
      ...toRefs(obj),
      xzclick,
    };
  },
};
</script>

<style lang="less" scoped>
.app-top-nav {
  background: rgba(95, 132, 199, 1);
  color: #fff;
  padding: 5px 0 0 0;
  .logo-img {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0px;
    bottom: 2px;
    img {
      width: 70px;
    }
  }
  .type-box {
    max-width: 800px;
    height: 70px;
    display: flex;
    margin-left: 304px;
    display: flex;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
    font-size: 24px;
    vertical-align: middle;
    div {
      flex: 1;
      height: 100%;
      vertical-align: middle;
      span {
        vertical-align: middle;
      }
      img {
        width: 23px;
        height: 24px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
    .xuanzho {
      border-radius: 5px 5px 0 0;
      background: rgba(117, 128, 229, 1);
    }
    div:hover {
      border-radius: 5px 5px 0 0;
      background: rgba(117, 128, 229, 1);
    }
  }
}
</style>
