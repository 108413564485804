<template>
  <!-- 1.顶部通栏 -->
  <div><AppTopNav /></div>
  <!-- 2.头部区域 -->
  <div><AppHeader /></div>
  <!-- 3.主体区域 -->
  <router-view></router-view>
  <!-- 4.底部区域 -->
  <div><Appbottomnav /></div>
</template>

<script>
import AppTopNav from "./student/components/Apptopnav.vue";
import AppHeader from "./student/components/Appheader.vue";
import AppLeft from "./student/components/Appleft.vue";
import Appbottomnav from "./student/components/Appbottomnav.vue";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
  name: "VueLayout",
  components: {
    AppTopNav,
    AppHeader,
    AppLeft,
    Appbottomnav,
  },
  setup() {
    const { proxy } = getCurrentInstance();
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
}
</style>
