<template>
  <div class="user_main_box">
    <div class="z_user_box">
      <div class="zuser_title">
        <div>{{ $t("user.title") }}</div>
        <div>
          <span @click="updateUserClick">{{ $t("user.save") }}</span>
        </div>
      </div>
      <div class="zuser_update">
        <div class="zuser_up_zuo">
          <div class="zuser_up_input">
            <div>{{ $t("user.text1") }}</div>
            <div>
              <input type="text" :value="userinfo.username" disabled="disabled" />
            </div>
          </div>
          <div class="zuser_up_input">
            <div>{{ $t("user.text2") }}</div>
            <div><input type="text" v-model="uservo.phone" /></div>
          </div>
          <div class="zuser_up_input">
            <div>{{ $t("user.text3") }}</div>
            <div><input type="text" v-model="uservo.wechat" /></div>
          </div>
        </div>
        <div class="zuser_up_yuo">
          <div class="zuser_up_input">
            <div>{{ $t("user.text4") }}</div>
            <div><input type="text" v-model="uservo.country" /></div>
          </div>
          <div class="zuser_up_input">
            <div></div>
            <div></div>
          </div>
          <div class="zuser_up_input">
            <div>WhatsApp</div>
            <div><input type="text" v-model="uservo.whats_app" /></div>
          </div>
        </div>
      </div>
    </div>
    <StudentUserList />
    <div v-for="(item, index) in userlist">
      <StudentUserAdd :index="index" />
    </div>
    <div class="adduser_box" @click="adduserjia">
      <img src="../../assets/img/adduser.png" alt="" />
    </div>
  </div>
</template>

<script>
import StudentUserAdd from "./componentsuser/StudentUserAdd.vue";
import StudentUserList from "./componentsuser/StudentUserList.vue";
import { useRouter } from "vue-router";
import { reactive, toRefs, onMounted } from "vue";
import { updateUser } from "@/api/getData";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default {
  name: "VueStudentContract",
  components: { StudentUserAdd, StudentUserList },
  setup() {
    const store = useStore();
    let lang = localStorage.getItem("language");
    const router = useRouter();
    const obj = reactive({
      userlist: 0,
      uservo: {},
      userinfo: store.state.User.userinfo,
      stuList: null,
    });
    const adduserjia = () => {
      obj.userlist = obj.userlist + 1;
    };
    const updateUserClick = async () => {
      let uservo = obj.uservo;
      const res = await updateUser(
        uservo.phone,
        uservo.wechat,
        uservo.country,
        uservo.whats_app
      );
      localStorage.setItem("user_vo", JSON.stringify(res.data.data));
      obj.uservo = res.data.data;
      ElMessage.success(lang == "en" ? "Modified successfully!" : "修改成功！");
    };

    onMounted(() => {
      obj.uservo = JSON.parse(localStorage.getItem("user_vo"));
    });
    return {
      ...toRefs(obj),
      adduserjia,
      updateUserClick,
    };
  },
};
</script>

<style lang="less" scoped>
.user_main_box {
  width: 900px;
  min-height: 78.4%;
  margin: 45px auto;
  box-sizing: border-box;
  .adduser_box {
    img {
      margin-top: 20px;
      margin-left: 20px;
      width: 40px;
    }
  }
  input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    border-radius: 15px;
    border: 1px solid #dcdfe6;
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
  }
  .z_user_box {
    width: 900px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 20px 20px;
    color: #dcdfe6;
    font-size: 20px;
    .zuser_update {
      width: 100%;
      display: flex;
      color: #000;
      .zuser_up_yuo {
        flex: 1;
        font-size: 20px;
        .zuser_up_input {
          height: 60px;
          line-height: 60px;
          display: flex;
          div:nth-child(1) {
            width: 100px;
            padding-left: 25px;
          }
        }
        .zuser_up_input:nth-child(1) {
          div:nth-child(1) {
            width: 120px;
          }
          div:nth-child(2) {
            width: 226px;
          }
        }
        .zuser_up_input:nth-child(3) {
          div:nth-child(1) {
            width: 120px;
          }
          div:nth-child(2) {
            width: 226px;
          }
        }
      }
      .zuser_up_zuo {
        flex: 1;
        .zuser_up_input {
          height: 60px;
          line-height: 60px;
          display: flex;
          font-size: 20px;
          div:nth-child(1) {
            width: 100px;
            padding-left: 25px;
          }
        }
        .zuser_up_input:nth-child(1) {
          div:nth-child(1) {
            width: 80px;
          }
          div:nth-child(2) {
            width: 232px;
            input {
              background-color: #e5e5e5;
            }
          }
        }
        .zuser_up_input:nth-child(3) {
          div:nth-child(1) {
            width: 80px;
          }
          div:nth-child(2) {
            width: 232px;
          }
        }
      }
    }
    .zuser_title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      div:nth-child(1) {
        color: #5f84c7;
        font-size: 20px;
      }
      div:nth-child(2) {
        span {
          color: #fff;
          background-color: #5f84c7;
          padding: 5px 10px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
