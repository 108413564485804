<template>
  <div class="order_main_box">
    <div class="order_title">{{ $t("order.title") }}</div>
    <div v-for="(item, index) in order" class="order_list_box">
      <div class="order_top">
        <!-- <div>2022-09-18 {{$t("index.pddate") == 1 ? "周三" : "WEN"}} 17:05 </div> -->
        <div>{{ item.addDate }}</div>
        <div>
          {{ $t("index.pddate") == 1 ? "订单号" : "Order No." }}: {{ item.orderNo }}
        </div>
      </div>
      <div class="order_list">
        <div class="ellipsis">
          {{ $t("index.pddate") == 1 ? item.courseZhName : item.courseEnName }}
        </div>
        <div>
          {{ item.courseTime + item.rawardTime }}
          {{ $t("index.pddate") == 1 ? "课时" : "Sessions" }}
        </div>
        <div>
          {{ item.actualPayment }}
          {{ item.moneyType == 1 ? "USD" : item.moneyType == 2 ? "RMB" : "SGD" }}
        </div>
        <div>
          {{
            item.payState == 2
              ? $t("index.pddate") == 1
                ? "已支付"
                : "Paid"
              : $t("index.pddate") == 1
              ? "未支付"
              : "Unpaid"
          }}
        </div>
        <div>
          <span @click="goToDetail(item.id)">{{
            $t("index.pddate") == 1 ? "查看详细" : "Detailes"
          }}</span>
        </div>
      </div>
      <div class="order_list_xia">
        <div></div>
        <div>
          +{{ item.rawardTime }}
          {{ $t("index.pddate") == 1 ? "奖励课时" : "Free Sessions" }}
        </div>
        <div>{{ item.originalPrice }} USD</div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- <div class="order_list_box">
      <div class="order_top">
        <div>2022-09-18 {{$t("index.pddate") == 1 ? "周三" : "WEN"}} 17:05</div>
        <div>{{$t("index.pddate") == 1 ? "订单号" : "Order No."}}: juhg-5632-dguy-9854-hjuk-5698</div>
      </div>
      <div class="order_list">
        <div>{{$t("index.pddate") == 1 ? "青少年汉语" : "JUV Course"}} </div>
        <div>60 {{$t("index.pddate") == 1 ? "课时" : "Sessions"}}</div>
        <div>1300.00 USD</div>
        <div>{{$t("index.pddate") == 1 ? "已支付" : "Paid"}}</div>
        <div><span @click="goToDetail()">{{$t("index.pddate") == 1 ? "查看详细" : "Detailes"}}</span></div>
      </div>
      <div class="order_list_xia">
        <div></div>
        <div>+2 {{$t("index.pddate") == 1 ? "奖励课时" : "Free Sessions"}}</div>
        <div>1300.00 USD</div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="order_list_box">
      <div class="order_top">
        <div>2022-09-18 {{$t("index.pddate") == 1 ? "周三" : "WEN"}} 17:05</div>
        <div>{{$t("index.pddate") == 1 ? "订单号" : "Order No."}}: juhg-5632-dguy-9854-hjuk-5698</div>
      </div>
      <div class="order_list">
        <div>{{$t("index.pddate") == 1 ? "青少年汉语" : "JUV Course"}} </div>
        <div>60 {{$t("index.pddate") == 1 ? "课时" : "Sessions"}}</div>
        <div>1300.00 USD</div>
        <div>{{$t("index.pddate") == 1 ? "已支付" : "Paid"}}</div>
        <div><span @click="goToDetail()">{{$t("index.pddate") == 1 ? "查看详细" : "Detailes"}}</span></div>
      </div>
      <div class="order_list_xia">
        <div></div>
        <div>+2 {{$t("index.pddate") == 1 ? "奖励课时" : "Free Sessions"}}</div>
        <div>1300.00 USD</div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="order_list_box">
      <div class="order_top">
        <div>2022-09-18 {{$t("index.pddate") == 1 ? "周三" : "WEN"}} 17:05</div>
        <div>{{$t("index.pddate") == 1 ? "订单号" : "Order No."}}: juhg-5632-dguy-9854-hjuk-5698</div>
      </div>
      <div class="order_list">
        <div>{{$t("index.pddate") == 1 ? "青少年汉语" : "JUV Course"}} </div>
        <div>60 {{$t("index.pddate") == 1 ? "课时" : "Sessions"}}</div>
        <div>1300.00 USD</div>
        <div>{{$t("index.pddate") == 1 ? "已支付" : "Paid"}}</div>
        <div><span @click="goToDetail()">{{$t("index.pddate") == 1 ? "查看详细" : "Detailes"}}</span></div>
      </div>
      <div class="order_list_xia">
        <div></div>
        <div>+2 {{$t("index.pddate") == 1 ? "奖励课时" : "Free Sessions"}}</div>
        <div>1300.00 USD</div>
        <div></div>
        <div></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, onMounted } from "vue";
import { getOrderList } from "@/api/getData";
import { ElMessage } from "element-plus";
export default {
  name: "VueStudentContract",
  components: {},

  setup(props) {
    const lang = localStorage.getItem("langauge");
    const router = useRouter();
    const obj = reactive({
      order: null,
    });
    const orderList = async () => {
      let user = JSON.parse(localStorage.getItem("current_student"));
      if (user == null) {
        ElMessage.error(
          lang == "en"
            ? "Only after selecting students can you view them!"
            : "选择学生后才能查看!"
        );
        return false;
      }
      try {
        const res = await getOrderList(user.id);
        obj.order = res.data.data;
        setTimeout(() => {
          try {
            res = getOrderList(user.id);
          } catch {
            return false;
          }
          obj.order = res.data.data;
        }, 1000);
      } catch {
        ElMessage.error(
          lang == "en"
            ? "Network abnormality, please refresh the page!"
            : "网络异常，请刷新页面!"
        );
        return false;
      }
    };
    const goToDetail = (id) => {
      router.push({
        nmae: "orderdetail",
        path: "/orderdetail",
        query: {
          user_id: id,
        },
      });
    };
    onMounted(() => {
      orderList();
    });
    return {
      ...toRefs(obj),
      goToDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.order_main_box {
  width: 900px;
  min-height: 997px;
  margin: 20px auto 0 auto;
  box-sizing: border-box;
  .order_title {
    width: 100%;
    height: 60px;
    line-height: 50px;
    font-size: 24px;
    box-sizing: border-box;
    padding-left: 25px;
    color: @xtxColor;
  }
  .order_list_box {
    width: 900px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px 25px;
    position: relative;
    margin-bottom: 20px;
    .myxing {
      position: absolute;
      top: 50%;
      left: -70px;
      margin-top: -15px;
      font-size: 30px;
      color: #ff8d1a;
    }
    .order_list_xia {
      width: 100%;
      color: #808080;
      font-size: 14px;
      display: flex;
      div {
        flex: 1;
      }
      div:nth-child(3) {
        text-decoration: line-through;
      }
    }
    .order_list {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      display: flex;
      div {
        flex: 1;
      }
      div:nth-child(1) {
        flex: 2;
      }
      div:nth-child(4) {
        text-align: center;
      }
      div:nth-child(5) {
        text-align: right;
        span {
          cursor: pointer;
          background-color: @xtxColor;
          color: #fff;
          border-radius: 8px;
          padding: 3px 10px;
        }
      }
    }
    .order_top {
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #808080;
      border-bottom: 2px solid #cccccc;
    }
  }
}
</style>
