<template>
  <div class="Recharge_user_box">
    <div class="user_box">
      <div class="user_zuo">
        <div class="user_zuo_z">
          <div v-if="current_student == null">
            <div class="user_zz_img">
              <img
                src="../../../assets/img/admin.png"
                alt=""
                @click.prevent="clickToUser"
              />
              <i
                class="iconfont icon-xiangyou3"
                :style="xuanzhuan"
                @click="showListClick"
              ></i>
            </div>
            <div v-if="student_length != 0" @click="showListClick">
              {{ $t("appleft.SelectSubAccount") }}
            </div>
            <div v-if="student_length == 0" @click.prevent="clickToUser">
              {{ $t("appleft.AddSubAccount") }}
            </div>
          </div>
          <div v-else @click="showListClick">
            <div class="user_zz_img">
              <img
                v-if="current_student.header_img"
                :src="current_student.header_img"
                alt=""
              />
              <img v-else src="../../../assets/img/tou.png" alt="" />
              <i class="iconfont icon-xiangyou3" :style="xuanzhuan"></i>
            </div>
            <span> {{ current_student.nickname }}</span>
          </div>
          <div :class="showList">
            <div class="user_li_box">
              <div class="user_li"></div>
              <!-- <div class="user_li" @click="showUserClick(404)">
                <img src="../../../assets/img/tou.png" alt="" />
                <div>
                  {{
                    userinfo.username.toString().indexOf("@") != -1
                      ? userinfo.username.split("@")[0]
                      : "admin"
                  }}
                </div>
              </div> -->
              <div
                v-for="(item, index) in student_list"
                class="user_li"
                @click="showUserClick(index)"
              >
                <img v-if="item.header_img" :src="item.header_img" alt="" />
                <img v-else src="../../../assets/img/tou.png" alt="" />
                <div>{{ item.nickname }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="user_zuo_y">
          <div class="user_zy_rem">
            {{ $t("recharge.Remaining") }}
            <span>{{
              current_student?.remaining == null
                ? 0
                : current_student?.total - current_student?.learned
            }}</span>
            {{ $t("recharge.s") }}
          </div>
          <div class="user_zy_lea">
            {{ $t("recharge.Learned") }}
            <span>{{
              current_student?.learned == null ? 0 : current_student?.learned
            }}</span>
            {{ $t("recharge.s") }}
          </div>
        </div>
      </div>
      <div class="user_you" @click="goCouponns()">
        <img src="../../../assets/img/qian.png" alt="" />
        <span>{{ $t("recharge.Coupons") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import 'swiper/css/navigation';
import "swiper/css/pagination";

// import './style.css';

// import required modules
import { Navigation, Pagination } from "swiper";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default {
  name: "AppUserHeader",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const goCouponns = () => {
      router.push({
        path: "/coupons",
      });
    };

    const obj = reactive({
      showList: "user_list",
      xuanzhuan: "",
      userinfo: store.state.User.userinfo,
      student_list: null,
      current_student: null,
      student_length: 0,
    });
    const showListClick = () => {
      obj.showList = obj.showList == "user_list" ? "user_list showList" : "user_list";
      obj.xuanzhuan = obj.xuanzhuan == "" ? "transform: rotateY(180deg);" : "";
    };
    const showUserClick = (index) => {
      obj.showList = obj.showList == "user_list" ? "user_list showList" : "user_list";
      obj.xuanzhuan = obj.xuanzhuan == "" ? "transform: rotateY(180deg);" : "";
      if (index == 404) {
        obj.current_student = null;
        localStorage.removeItem("current_student");
        store.commit("SET_COURRENT_STUDENT", null);
        return false;
      }
      obj.current_student = obj.student_list[index];
      store.commit("SET_COURRENT_STUDENT", obj.student_list[index]);
      localStorage.setItem("current_student", JSON.stringify(obj.student_list[index]));
      let str = localStorage.getItem("language");
      ElMessage({
        message: str == "en" ? "Successfully switched account!" : "切换账号成功！",
        type: "success",
      });
    };
    const goToUser = () => {
      router.push({
        path: "/user",
      });
    };
    onMounted(() => {
      store.commit("updateName", localStorage.getItem("emil"));
      obj.current_student =
        localStorage.getItem("current_student") == undefined
          ? null
          : JSON.parse(localStorage.getItem("current_student"));
      obj.student_list = JSON.parse(localStorage.getItem("student_list"));
      obj.student_length = Object.keys(obj.student_list).length;
    });

    const clickToUser = () => {
      router.push({
        path: "/user",
      });
    };
    return {
      goCouponns,
      goToUser,
      ...toRefs(obj),
      showListClick,
      showUserClick,
      modules: [Navigation, Pagination],
      clickToUser,
    };
  },
};
</script>

<style lang="less" scoped>
.Recharge_user_box {
  width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  .user_box {
    width: 100%;
    justify-content: space-between;
    display: flex;
    .user_you {
      width: 250px;
      height: 140px;
      background-color: #7580e5;
      color: #fff;
      text-align: center;
      line-height: 140px;
      border-radius: 16px;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 53px;
        height: 46px;
        margin-right: 20px;
        vertical-align: middle;
      }
      span {
        font-size: 24px;
        vertical-align: middle;
      }
    }
    .user_zuo {
      width: 520px;
      height: 140px;
      background: #5f84c7;
      border-radius: 16px;
      color: #fff;
      display: flex;
      position: relative;
      .my_xing {
        position: absolute;
        top: -30px;
        left: 50%;
        width: 30px;
        height: 30px;
        margin-left: -20px;
      }
      .user_zuo_y {
        box-sizing: border-box;
        padding-left: 10px;
        .user_zy_rem {
          width: 100%;
          height: 70px;
          line-height: 80px;
        }
        div {
          font-size: 20px;
          span {
            font-size: 24px;
            margin: 0 10px;
          }
        }
      }
      .user_zuo_z {
        padding-top: 18px;
        box-sizing: border-box;
        width: 200px;
        height: 100%;
        text-align: center;
        position: relative;
        .showList {
          animation-duration: 0.35s;
          animation-name: slidein;
          animation-fill-mode: forwards;
        }
        .xuanzhuan {
          transform: rotateY(180deg);
        }
        .xuanzhuan2 {
          transform: rotateY(-180deg);
        }
        @keyframes slidein {
          0% {
            left: -100px;
            width: 0;
            opacity: 0;
          }
          100% {
            left: 0;
            width: auto;
            opacity: 1;
          }
        }
        .user_list {
          position: absolute;
          transition: 0.35s;
          top: 0px;
          left: -100px;
          // width: 800px;
          width: 0;
          height: 140px;
          border-radius: 20px;
          background-color: #5f84c7;
          box-shadow: 0px 0px 10px #5f84c7;
          overflow: hidden;
          z-index: 10;
          .user_li_box {
            height: 100%;
            display: flex;
            .scrollbar-flex-content {
              display: flex;
            }
            .scrollbar-demo-item {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 50px;
              margin: 10px;
              text-align: center;
              border-radius: 4px;
              background: var(--el-color-danger-light-9);
              color: var(--el-color-danger);
            }
            .user_li {
              display: inline-block;
              margin: 0 10px;
              cursor: pointer;
              box-sizing: border-box;
              padding-top: 20px;
              width: 110px;
              height: 90%;
              font-size: 12px;
              img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
              }
            }
            .user_li:nth-child(1) {
              margin-right: 50px;
            }
            .user_li::-webkit-scrollbar {
              width: 0px;
            }
          }
        }
        div {
          font-size: 18px;
          z-index: 20;
          position: relative;
          cursor: pointer;
          margin: 0;
        }
        .user_zz_img {
          cursor: pointer;
          width: 90px;
          height: 90px;
          margin: auto;
          position: relative;
          z-index: 20;
          i {
            cursor: pointer;
            position: absolute;
            font-size: 25px;
            top: 50%;
            margin-top: -9px;
            right: -45px;
            font-weight: 100;
            transition: 0.35s;
          }
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
/deep/.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none;
}
</style>
