<template>
  <div class="app-top-nav">
    <div class="w">
      <div class="contact">
        <i class="iconfont icon-kefu"></i> <span>{{ $t("apptopnav.Contact_Us") }}</span>
      </div>
      <div class="right-box">
        <div class="my-emil" v-if="isLogin == 1" @click="dropdownClick2()">
          <el-dropdown ref="dropdown2" trigger="contextmenu">
            <span class="el-dropdown-link"> {{ userinfo.username }} </span>
            <template #dropdown>
              <el-radio-group v-model="resource" @click="resClick">
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-radio :label="1">
                      {{
                        $t("index.pddate") == 1 ? "个人中心" : "Personal Center"
                      }}</el-radio
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-radio :label="2">
                      {{ $t("index.pddate") == 1 ? "我的订单" : "My Orders" }}</el-radio
                    >
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <el-radio :label="3">
                      {{
                        $t("index.pddate") == 1 ? "修改密码" : "Reset-Password"
                      }}</el-radio
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-radio :label="4">
                      {{
                        $t("index.pddate") == 1 ? "退出登录" : "Log out of login"
                      }}</el-radio
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-radio-group>
            </template>
          </el-dropdown>
          <i class="iconfont icon-xialajiantouxiao"></i>
        </div>
        <div class="my-switch" @click="showClick()">
          <img src="../../../assets/img/wa.png" alt="" />

          <el-dropdown ref="dropdown1" trigger="contextmenu">
            <span class="el-dropdown-link">
              {{ lang == 1 ? "中\xa0\xa0文" : "English" }}
            </span>
            <template #dropdown>
              <el-radio-group v-model="lang" @change="updateLang">
                <el-dropdown-menu>
                  <el-dropdown-item
                    ><el-radio :label="1">
                      中&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文
                    </el-radio></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-radio :label="2"> English </el-radio></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-radio-group>
            </template>
          </el-dropdown>

          <i class="iconfont icon-xiala"></i>
        </div>
      </div>
    </div>
    <StudentUserUpdate v-if="resource == 3" @cancelClick="cancelClick" />
  </div>
  <div v-if="resource == 3" class="zhegai"></div>
</template>

<script>
import { toRefs, reactive, watch, onMounted, inject, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import StudentUserUpdate from "../componentsuser/StudentUserUpdate.vue";
import { useI18n } from "vue-i18n";
import { ElMessageBox } from 'element-plus'
export default {
  name: "VueApptopnav",
  components: { StudentUserUpdate },
  setup() {
    const reload = inject("reload");
    const toggle = inject("toggle");
    const store = useStore();
    const router = useRouter();
    const obj = reactive({
      userinfo: store.state.User.userinfo,
      resource: 0,
      lang: 1,
      isLogin: 0,
    });
    const { locale } = useI18n();

    watch(
      () => obj.resource,
      () => {
        switch (obj.resource) {
          case 1:
            router.push({
              path: "/user",
            });
            break;
          case 2:
            router.push({
              path: "/order",
            });
            break;
          case 4:
            ElMessageBox.confirm(
              obj.lang == 1 ? "确定退出登录吗？" : "Are you sure to log out?"
            ).then(() => {
              localStorage.removeItem("emil");
              localStorage.removeItem("user_vo");
              localStorage.removeItem("student_list");
              localStorage.removeItem("token");
              localStorage.removeItem("current_student", null);
              store.commit("SET_STUDENT_LIST", null);
              store.commit("updateName", null);
              store.commit("SET_TOKEN", null);
              router.push({
                path: "/login",
              });
            }).catch(() => {
      // catch error
    });
            break;
        }
      }
    );
    const resClick = () => {
      switch (obj.resource) {
        case 1:
          router.push({
            path: "/user",
          });
          break;
        case 2:
          router.push({
            path: "/order",
          });
          break;
      }
    };
    onMounted(() => {
      obj.lang = localStorage.getItem("language") == "en" ? 2 : 1;
      if (localStorage.getItem("token") != "" && localStorage.getItem("token") != null) {
        obj.isLogin = 1;
      }
      store.commit("updateName", localStorage.getItem("emil"));
    });
    const dropdown1 = ref();
    const showClick = () => {
      if (!dropdown1.value) return;
      {
        dropdown1.value.handleOpen();
      }
    };
    const dropdown2 = ref();
    const dropdownClick2 = () => {
      if (!dropdown2.value) return;
      dropdown2.value.handleOpen();
    };
    const updateLang = (e) => {
      switch (e) {
        case 1:
          localStorage.setItem("language", "zh");
          store.commit("updateLang", "zh");
          locale.value = "zh";
          break;
        case 2:
          localStorage.setItem("language", "en");
          store.commit("updateLang", "en");
          locale.value = "en";
          break;
      }
      toggle();
      reload();
    };

    const cancelClick = () => {
      obj.resource = 0;
    };
    const clickLang = (index) => {
      obj.lang = index;
    };
    return {
      ...toRefs(obj),
      cancelClick,
      updateLang,
      resClick,
      showClick,
      dropdown1,
      dropdownClick2,
      dropdown2,
      clickLang,
    };
  },
};
</script>

<style lang="less" scoped>
// @import url("../common/css/varibles.less");
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
.my-el-popper {
  z-index: 2005;
  width: 400px;
  position: absolute;
  box-shadow: 0 0 0 0 !important;
  border: none !important;
  inset: 2px 286px auto auto;
  background: rgba(0, 0, 0, 0) !important;
}
.el-dropdown {
  vertical-align: middle;
}
/deep/.el-dropdown-menu__item {
  padding: 0;
  width: 100%;
}
/deep/.el-radio {
  padding: 5px 20px;
}
.el-dropdown-link {
  font-size: 10.05px;
  color: #fff;
  vertical-align: middle;
  .el-radio {
    width: 100%;
    height: 100%;
  }
  /deep/.el-dropdown-menu__item {
    width: 100%;
    height: 100%;
    padding: none;
  }
}
.el-dropdown-link:focus {
  outline: none;
}
.app-top-nav {
  background: rgba(95, 132, 199, 1);
  color: #fff;
  height: 40px;
  line-height: 40px;
  // padding: 5px 0;
  .contact {
    cursor: pointer;
    margin: 0;
    font-size: 14.67px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    float: left;
    span {
      font-size: 14.67px;
      vertical-align: top;
    }
    i {
      font-size: 20px;
      vertical-align: top;
    }
  }
  .right-box {
    vertical-align: middle;
    i {
      vertical-align: middle;
    }
  }
  .my-emil {
    float: right;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: middle;
    margin: 0 0px 0 40px;
    span {
      font-size: 14.67px;
      vertical-align: middle;
    }
    i {
      margin-left: 8px;
      font-size: 14.67px;
      vertical-align: -3px;
    }
  }
  .my-switch {
    float: right;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 15px;
    // box-sizing: border-box;
    vertical-align: middle;
    // min-width: 120px;
    padding: 0 6px 0 12px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    margin-top: 7px;
    span {
      // margin: 0 5px 5px 5px;
      font-size: 13px;
      margin: 0 6px;
    }
    img {
      vertical-align: middle;
      width: 21px;
      height: 17px;
    }
    i {
      vertical-align: middle;
    }
  }
}
</style>
