<template>
  <div class="app-cs-nav clearfix">
    <Appyoutopnav />
    <div class="cs_title_box">
      <div class="cs_tit_bot">
        <el-button :class="buttonList[0].type" @click="buttonClick(0)">{{
          $t("course.cs_tit_bot1")
        }}</el-button>
      </div>
      <div class="cs_tit_bot">
        <el-button :class="buttonList[1].type" @click="buttonClick(1)">{{
          $t("course.cs_tit_bot2")
        }}</el-button>
      </div>
      <div class="cs_tit_bot">
        <el-button :class="buttonList[2].type" @click="buttonClick(2)">{{
          $t("course.cs_tit_bot3")
        }}</el-button>
      </div>
      <div class="cs_tit_bot">
        <el-button :class="buttonList[3].type" @click="buttonClick(3)">{{
          $t("course.cs_tit_bot4")
        }}</el-button>
      </div>
    </div>
    <div class="cs_list_box">
      <div class="CA_box">
        <div v-for="(item, index) in 0" class="ca_b_list">
          <div>{{ index + 1 }}</div>
          <div>
            HSK {{ $t("course.pddate") == "1" ? "中文课" : "Course" }} Level1 Lesson1
            <el-tooltip
              v-if="item == 2"
              content="Student Absence"
              placement="top"
              effect="light"
            >
              <i class="iconfont icon-tishi STUAbsence"></i>
            </el-tooltip>
            <el-tooltip
              v-if="item == 4"
              content="Student Mistime"
              placement="top"
              effect="light"
            >
              <i class="iconfont icon-tishi STUMistime"></i>
            </el-tooltip>
            <el-tooltip
              v-if="item == 6"
              content="Teacher Tardiness"
              placement="top"
              effect="light"
            >
              <i class="iconfont icon-tishi1 TEATardiness"></i>
            </el-tooltip>
            <el-tooltip
              v-if="item == 8"
              content="Teacher Absence"
              placement="top"
              effect="light"
            >
              <i class="iconfont icon-tishi1 TEAAbsence"></i>
            </el-tooltip>
          </div>
          <div>{{ $t("course.pddate") == "1" ? "9月1日" : "Jun.1" }}</div>
          <div>07:00-07:55</div>
          <div>{{ $t("course.pddate") == "1" ? "约课" : "Appoint" }}</div>
        </div>
      </div>
      <div class="ca_b_page" v-if="1 == 0">
        <i class="iconfont icon-xiangzuojiantou"></i>
        <span>{{ $t("course.pddate") == "1" ? "翻页" : "Turn" }}</span>
        <i class="iconfont icon-xiangyoujiantou"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Appyoutopnav from "./components/Appyoutopnav.vue";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "VueAppcourserecord",
  components: { Appyoutopnav },
  setup(props) {
    const store = useStore();

    const obj = reactive({
      userinfo: store.state.User.userinfo,
      buttonList: [{ type: "cs_tit_bot_one" }, { type: "" }, { type: "" }, { type: "" }],
    });

    // 顶部分类 begin
    const buttonClick = (index) => {
      const buttonList = obj.buttonList;
      for (let i = 0; i < buttonList.length; i++) {
        buttonList[i].type = "";
      }
      let click = "";
      switch (index) {
        case 0:
          click = "cs_tit_bot_one";
          break;
        case 1:
          click = "cs_tit_bot_two";
          break;
        case 2:
          click = "cs_tit_bot_three";
          break;
        case 3:
          click = "cs_tit_bot_four";
          break;
      }
      buttonList[index].type = click;
    }; // end

    return {
      ...toRefs(obj),
      buttonClick,
    };
  },
};
</script>

<style lang="less" scoped>
.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
.app-cs-nav {
  box-sizing: border-box;
  padding-top: 45px;
  margin-left: 75px;
  max-width: 856px;
  width: 856px;
  .STUAbsence {
    cursor: pointer;
    font-size: 24px;
    float: right;
    color: #bbe3a3;
    margin-right: 15px;
    /deep/.el-popper.is-customized .el-popper__arrow::before {
      background: linear-gradient(45deg, #b2e68d, #bce689);
      right: 0;
    }
  }
  .STUMistime {
    cursor: pointer;
    font-size: 24px;
    float: right;
    color: #f7a654;
    margin-right: 15px;
  }
  .TEATardiness {
    cursor: pointer;
    font-size: 24px;
    float: right;
    color: #e6d64c;
    margin-right: 15px;
  }
  .TEAAbsence {
    cursor: pointer;
    font-size: 24px;
    float: right;
    color: #ed6873;
    margin-right: 15px;
  }
  .ca_b_page {
    color: #5f84c7;
    span {
      cursor: pointer;
      margin: 0 10px;
      font-size: 18px;
    }
    i {
      cursor: pointer;
      font-size: 15px;
    }
  }
  .CA_box {
    box-sizing: border-box;
    font-size: 15px;
    color: #5f84c7;
    height: 642px;
    .ca_b_list {
      display: flex;
      position: relative;
      margin-bottom: 20px;
      font-size: 20px;
      .ca_b_yuan {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #5f84c7;
        border: 4.7px solid #5f84c7 !important;
      }
      .ca_b_yuan1 {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
        border: 2px solid #5f84c7 !important;
      }
      .ca_b_yuan1::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 2px;
        margin-left: -1px;
        margin-top: -1px;
        background: #5f84c7;
        border-radius: 50%;
      }
      .ca_b_xian {
        position: absolute;
        top: 18px;
        left: 4px;
        width: 1px;
        height: 33px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #5f84c7;
        border: 1 solid #5f84c7;
      }
      div:nth-child(1) {
        flex: 1;
        text-align: center;
      }
      div:nth-child(2) {
        flex: 5;
      }
      div:nth-child(3) {
        flex: 2;
        text-align: center;
      }
      div:nth-child(4) {
        flex: 2;
        text-align: right;
      }
      div:nth-child(5) {
        flex: 2;
        padding-right: 35px;
        text-align: right;
      }
    }
    .ca_b_list:nth-child(3) {
      .ca_b_xian {
        border: none;
        background: none;
      }
    }
  }
  .cs_title_box {
    margin-top: 56px;
    width: 100%;
    height: 70px;
    display: flex;
    .cs_tit_bot {
      flex: 1;
      text-align: center;
      line-height: 60px;
      font-size: 22px;
      .el-button {
        display: inline-block;
        text-align: center;
        border-radius: 20px;
        margin: 0 auto;
        font-size: 20px;
        padding: 6px 10px 5px 10px;
      }
    }
    .cs_tit_bot:nth-child(1) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top: 5px solid #5f84c7;
    }
    .cs_tit_bot:nth-child(1) {
      .el-button:hover {
        background: #5f84c7;
        color: #fff;
      }
    }
    .cs_tit_bot_one {
      background: #5f84c7;
      color: #fff;
    }
    .cs_tit_bot:nth-child(2) {
      border-top: 5px solid #7580e5;
    }
    .cs_tit_bot:nth-child(2) {
      .el-button:hover {
        background: #7580e5;
        color: #fff;
      }
    }
    .cs_tit_bot_two {
      background: #7580e5;
      color: #fff;
    }
    .cs_tit_bot:nth-child(3) {
      border-top: 5px solid #ff8d1a;
    }
    .cs_tit_bot:nth-child(3) {
      .el-button:hover {
        background: #ff8d1a;
        color: #fff;
      }
    }
    .cs_tit_bot_three {
      background: #ff8d1a;
      color: #fff;
    }
    .cs_tit_bot:nth-child(4) {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: 5px solid #56b854;
    }
    .cs_tit_bot:nth-child(4) {
      .el-button:hover {
        background: #56b854;
        color: #fff;
      }
    }
    .cs_tit_bot_four {
      background: #56b854;
      color: #fff;
    }
  }
}
</style>
