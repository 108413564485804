<template>
  <div class="app-top-nav clearfix">
    <div class="totalbox">
      <div class="title">{{ $t("homepage.title2") }}</div>
      <span class="text"
        >{{ current_student?.total == null ? 0 : current_student?.total }}
        {{ $t("homepage.s") }}</span
      >
    </div>
    <div class="totalbox">
      <div class="title">{{ $t("homepage.title3") }}</div>
      <span class="text"
        >{{ current_student?.learned == null ? 0 : current_student?.learned }}
        {{ $t("homepage.s") }}</span
      >
    </div>
    <div class="totalbox">
      <div class="title">{{ $t("homepage.title4") }}</div>
      <span class="text"
        >{{
          current_student?.remaining == null
            ? 0
            : current_student?.total - current_student?.learned
        }}
        {{ $t("homepage.s") }}</span
      >
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "VueApptopnav",
  setup(props) {
    const store = useStore();

    const obj = reactive({
      userinfo: store.state.User.userinfo,
    });
    const current_student = computed(() => {
      store.commit(
        "SET_COURRENT_STUDENT",
        JSON.parse(localStorage.getItem("current_student"))
      );
      return store.state.User.current_student;
    });
    onMounted(() => {});
    return {
      ...toRefs(obj),
      current_student,
    };
  },
};
</script>

<style lang="less" scoped>
.app-top-nav {
  box-sizing: border-box;
  // padding-top: 30px;
  display: flex;
  margin: 0 auto;
  width: 856px;
  .totalbox {
    width: 240px;
    height: 140px;
    text-align: center;
    color: #fff;
    background: rgba(95, 132, 199, 1);
    border-radius: 10px;
    .title {
      font-size: 23px;
      width: 100%;
      height: 80px;
      line-height: 80px;
    }
    .text {
      font-size: 22px;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 5px 15px;
      border-radius: 20px;
    }
  }
  .totalbox:nth-child(1) {
    float: left;
  }
  .totalbox:nth-child(2) {
    background-color: #5c7e96;
    margin: 0 auto;
  }
  .totalbox:nth-child(3) {
    background-color: #7580e5;
    float: right;
  }
}
</style>
