import { createRouter, createWebHashHistory } from 'vue-router'
import { useStore } from "vuex";
import Layout from "../views/Layout.vue";
import StudentCourse from "../views/student/StudentCourse.vue";
import StudentRecharge from "../views/student/StudentRecharge.vue";
import Apphomepage from "../views/student/Apphomepage";
import Appointment from "../views/student/Appointment";
import Appschedule from "../views/student/Appschedule";
import Appcourserecord from "../views/student/Appcourserecord";
import Appmessage from "../views/student/Appmessage";
import StudentDiscount from "../views/student/StudentDiscount";
import StudentContract from "../views/student/StudentContract";
import StudentUser from "../views/student/StudentUser";
import StudentUserOrder from "../views/student/componentsuser/StudentUserOrder";
import StudentUserUpdate from "../views/student/componentsuser/StudentUserUpdate";
import StudentUserOrderDetail from "../views/student/componentsuser/StudentUserOrderDetail";
const Register = () => import("../views/Register.vue");
const Login = () => import("../views/Login.vue");
const Share = () => import("../views/share/index.vue");
const Coupons = () => import("../views/student/componentrecharge/AppCoupons.vue");
const billing = () => import("../views/student/componentrecharge/AppBilling.vue");
const NextStep = () => import("../views/student/componentrecharge/AppNextStep.vue");

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    meta: {
      isAuth: true,
    },
    children: [
      {
        path: "/",
        component: StudentCourse,
        meta: {
          isAuth: true,
        },
        children: [
          {
            path: "/",
            component: Apphomepage,
            meta: {
              isAuth: true,
            },
          },
          {
            path: "/intment",
            component: Appointment,
            meta: {
              isAuth: true,
            },
          },
          {
            path: "/schedule",
            component: Appschedule,
            meta: {
              isAuth: true,
            },
          },
          {
            path: "/courec",
            component: Appcourserecord,
            meta: {
              isAuth: true,
            },
          },
          {
            path: "/message",
            component: Appmessage,
            meta: {
              isAuth: true,
            },
          }
        ]
      },
      {
        path: "/recharge",
        component: StudentRecharge,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/coupons",
        component: Coupons,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/billing",
        component: billing,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/nextstep",
        component: NextStep,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/discount",
        component: StudentDiscount,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/contract",
        component: StudentContract,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/user",
        component: StudentUser,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/order",
        component: StudentUserOrder,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/orderdetail",
        component: StudentUserOrderDetail,
        meta: {
          isAuth: true,
        },
      },
      {
        path: "/userupdate",
        component: StudentUserUpdate,
        meta: {
          isAuth: true,
        },
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/share",
    name: "share",
    component: Share
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/card",
    name: "card",
    component:()=> import("@/views/student/componentrecharge/AirwallexCard.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.meta.isAuth){
    if(localStorage.getItem("token")!="" && localStorage.getItem("token")!=null){
      next();
    }else{
      router.push("./login");
    }
  }else{
    next();
  }
})
export default router
