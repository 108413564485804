<template>
  <div class="user_add_box">
    <div class="z_user_box">
      <div class="zuser_title">
        <div v-if="stuList.nickname == null || stuList.nickname == ''">
          {{ "student " + (index + 1) }}
        </div>
        <div v-else>{{ stuList.nickname }}</div>
        <div>
          <span @click="addClickStudent">{{ $t("user.save") }}</span>
        </div>
      </div>
      <div class="stuser_box">
        <div class="stuser_hang">
          <div>
            <span>{{ $t("user.user1") }}</span>
          </div>
          <div><input type="text" name="nickname" v-model="stuList.nickname" /></div>
        </div>
      </div>
      <div class="stuser_box">
        <div class="stuser_hang">
          <div>
            <span>{{ $t("user.user2") }}</span>
          </div>
          <div>
            <span
              :class="stuList.sex == '男' ? 'sexNanClick' : ''"
              @click="onSexClick(0)"
              >{{ $t("user.user3") }}</span
            >
            <span
              @click="onSexClick(1)"
              :class="stuList.sex == '女' ? 'sexNvClick' : ''"
              >{{ $t("user.user4") }}</span
            >
          </div>
        </div>
      </div>
      <div class="stuser_box">
        <div class="stuser_hang">
          <div>
            <span>{{ $t("user.user5") }}</span>
          </div>
          <div>
            <el-date-picker
              v-model="stuList.birthdate"
              type="date"
              placeholder="Year/Month/Day"
              :size="size"
            />
          </div>
        </div>
      </div>
      <div class="user_img" @click="clickLoading">
        <label for="file">
          <el-upload
            v-loading="loading"
            name="file"
            class="avatar-uploader"
            action="https://victor.wxc1997cheshi.top/student-server/api/v1/user/student/upload"
            :show-file-list="false"
            :on-success="
              ($event) => {
                handleAvatarSuccess($event);
              }
            "
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="stuList.header_img" :src="stuList.header_img" class="avatar" />
            <!-- <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon> -->
            <i v-else class="iconfont icon-zengjia"><Plus /></i>
          </el-upload>
        </label>
      </div>
      <div v-if="putDet == 0" @click="putDetClick" class="detailes">
        {{ $t("user.user14") }} <i class="iconfont icon-xiangxiajiantou"></i>
      </div>
      <div v-if="putDet == 1" class="put_away">
        <div class="put_box" @click="putDetClick">
          {{ $t("user.user15") }} <i class="iconfont icon-xiangshangjiantou"></i>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user6") }}</span>
            </div>
            <div><input type="text" v-model="stuList.grade" /></div>
          </div>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user7") }}</span>
            </div>
            <div><input type="text" v-model="stuList.literacy" /></div>
          </div>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user8") }}</span>
            </div>
            <div><input type="text" v-model="stuList.writing" /></div>
          </div>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user9") }}</span>
            </div>
            <div><input type="text" v-model="stuList.tongue" /></div>
          </div>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user10") }}</span>
            </div>
            <div>
              <el-radio-group v-model="stuList.is_py" class="ml-4">
                <el-radio :label="1" size="large">{{
                  $t("index.pddate") == 1 ? "是" : "Yes"
                }}</el-radio>
                <el-radio :label="2" size="large">{{
                  $t("index.pddate") == 1 ? "否" : "No"
                }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="stuser_box">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user11") }}</span>
            </div>
            <div>
              <el-radio-group v-model="stuList.is_zn" class="ml-4">
                <el-radio :label="1" size="large">{{
                  $t("index.pddate") == 1 ? "是" : "Yes"
                }}</el-radio>
                <el-radio :label="2" size="large">{{
                  $t("index.pddate") == 1 ? "否" : "No"
                }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="stuser_box_text">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user12") }}</span>
            </div>
            <div>
              <textarea
                v-model="stuList.experience"
                placeholder=""
                placeholder-class="textarea-placeholder"
                @input=""
              />
            </div>
          </div>
        </div>
        <div class="stuser_box_text">
          <div class="stuser_hang">
            <div>
              <span>{{ $t("user.user13") }}</span>
            </div>
            <div>
              <textarea
                v-model="stuList.desire"
                placeholder=""
                placeholder-class="textarea-placeholder"
                @input=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { addStudent } from "@/api/getData";
import { useStore } from "vuex";
export default {
  name: "VueStudentContract",
  components: {},
  props: ["index"],
  setup(props) {
    let lang = localStorage.getItem("language");
    const store = useStore();
    const obj = reactive({
      putDet: 0,
      stuid: props.index == undefined ? "" : props.index + 1,
      stuList: {},
      studentId: null,
    });

    const onSexClick = (num) => {
      obj.stuList.sex = num == 0 ? "男" : "女";
    };

    const addClickStudent = async () => {
      ElMessage.success(lang == "en" ? "Successfully saved!" : "保存成功！");
      obj.stuList.emil = localStorage.getItem("emil");
      const res = await addStudent(obj.stuList);
      if (res.data.code == 0) {
        localStorage.setItem("student_list", JSON.stringify(res.data.data.student_vos));
        store.commit("SET_STUDENT_LIST", res.data.data.student_vos);
        obj.stuList.id = res.data.data.student_id;
      } else {
        ElMessage.error(lang == "en" ? "Save failed!" : "保存失败！");
      }
    };

    /**
     * 获取学生列表
     */
    onMounted(() => {});
    const putDetClick = () => {
      obj.putDet = obj.putDet == 0 ? 1 : 0;
    };

    const imageUrl = ref("");

    const handleAvatarSuccess = ($event) => {
      obj.stuList.header_img = $event.data;
      loading.value = false;
    };

    const beforeAvatarUpload = (rawFile) => {
      if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
        return false;
      }
      return true;
    };
    const loading = ref(false);
    const clickLoading = () => {
      loading.value = true;
    };
    return {
      loading,
      clickLoading,
      ...toRefs(obj),
      putDetClick,
      handleAvatarSuccess,
      beforeAvatarUpload,
      imageUrl,
      onSexClick,
      addClickStudent,
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.el-loading-spinner {
  top: 18%;
}
/deep/.el-radio.el-radio--large .el-radio__inner {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.el-radio__label {
  font-size: 30px;
}
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 1.36rem;
  height: 0.19rem;
  line-height: 0.19rem;
  border-radius: 0.01rem;
  // border: 1px solid #DCDFE6;
  outline: none;
  box-sizing: border-box;
  font-size: 0.08rem;
}
/deep/.el-input__wrapper,
.el-input {
  width: 1.36rem;
  border-radius: 0.08rem;
}
.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.put_away {
  position: relative;
  .put_box {
    position: absolute;
    cursor: pointer;
    bottom: -20px;
    right: 0;
    width: 150px;
    color: #5f84c7;
    font-size: 20px;
    text-align: right;
    i {
      font-size: 20px;
    }
  }
}
.user_add_box {
  width: 900px;
  margin: 30px auto 0 auto;
  box-sizing: border-box;
  font-size: 20px;
  .z_user_box {
    width: 900px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 20px;
    .user_img {
      position: absolute;
      top: 80px;
      left: 79px;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      border: 1px solid #dcdfe6;
      overflow: hidden;
      i {
        font-size: 40px;
        color: #dcdfe6;
      }
      .avatar {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100px;
        height: 100%;
        border-radius: 50%;
      }
    }

    .detailes {
      position: absolute;
      cursor: pointer;
      top: 180px;
      right: 20px;
      width: 150px;
      color: #5f84c7;
      font-size: 20px;
      text-align: right;
      i {
        font-size: 20px;
      }
    }
    .stuser_box_text {
      width: 100%;
      margin-bottom: 20px;
      .stuser_hang {
        line-height: 50px;
        display: flex;
        /deep/.el-radio__inner {
          width: 20px;
          height: 20px;
        }
        div:nth-child(1) {
          flex: 1;
          text-align: right;
          box-sizing: border-box;
          padding-right: 40px;
          height: 100px;
        }
        div:nth-child(2) {
          flex: 1.3;
          box-sizing: border-box;
          padding-top: 18px;
          height: 100px;
          input {
            width: 200px;
            height: 100px;
          }
        }
      }
    }
    .stuser_box {
      width: 100%;
      .stuser_hang {
        height: 50px;
        line-height: 50px;
        display: flex;
        div:nth-child(1) {
          flex: 1;
          text-align: right;
          box-sizing: border-box;
          padding-right: 40px;
        }
        div:nth-child(2) {
          flex: 1.3;
          input {
            width: 260px;
          }
          span:nth-child(1) {
            background-color: #84d6d6;
            border-radius: 10px;
            padding: 5px 20px;
            color: #fff;
            cursor: pointer;
          }
          span:nth-child(1):hover {
            background-color: #07dbdb;
          }
          span:nth-child(2) {
            cursor: pointer;
            background-color: #fbddd7;
            border-radius: 10px;
            padding: 5px 22px;
            color: #fff;
            margin-left: 20px;
          }
          span:nth-child(2):hover {
            background-color: #f76f57;
          }
          .sexNanClick {
            background-color: #07dbdb !important;
          }
          .sexNvClick {
            background-color: #f76f57 !important;
          }
        }
      }
    }

    .zuser_title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      div:nth-child(1) {
        color: #5f84c7;
        font-size: 20px;
      }
      div:nth-child(2) {
        span {
          color: #fff;
          background-color: #5f84c7;
          padding: 5px 10px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
  input {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border-radius: 15px;
    border: 1px solid #dcdfe6;
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
  }
  textarea {
    width: 260px;
    height: 100px;
    line-height: 25px;
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 13px;
    resize: none;
  }
}
</style>
