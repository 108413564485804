import axios from "../wbrequest.js";

// 获取汇率信息
export const getHuilv = (fromCode, money, toCode) => axios({
    method: "get",
    headers: { Authorization: "APPCODE ca81dc1b1b1242139e16f0cb37f1b3cc" },
    url: `/waihui-transform?fromCode=${fromCode}&money=${money}&toCode=${toCode}`,
})



