import axios from "../request";

/**
 * 用户相关接口
 * @param {*} emil 
 * @param {*} password 
 * @returns 
 */
//注册接口
export const registerApi = (emil,password) => axios.post("/student-server/api/v1/user/student/register",{
    emil,password
})

//登录接口
export const loginApi = (emil,password) => axios.post("/student-server/api/v1/user/student/login",{
    emil,password
})
//添加子账号
export const addStudent = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/user/student/add_student",
    data:data
})
//主账号修改
export const updateUser = (phone,wechat,country,whats_app) => axios.post("/student-server/api/v1/user/student/update",{
    phone,wechat,country,whats_app
})
export const updatePassword = (old_password,password) => axios.post("/student-server/api/v1/user/student/update",{
    old_password,password
})


// 获取LKL拉卡拉创建的支付信息
export const createLKLOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/create_lkl_order",
    data:data
})
//获取子账号列表
export const getStudentList = () => axios.get("/student-server/api/v1/user/student/student_list");
//用户发送反馈和建议
export const addMessage = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/message/add_message",
    data:data
})
//获取消息列表
export const getMessage = () => axios.get("/student-server/api/v1/message/get_message");








/**
 * 课程商品相关接口
 * @returns 
 */

//获取课程商品列表
export const getRecharge = () => axios.get("/recharge-server/api/v1/course_type/course_list");

//获取优惠券列表
export const getCouponList = (is_state,student_id) => axios.get("/recharge-server/api/v1/coupon/coupon_list/"+is_state+"/"+student_id);

//添加优惠券
export const addCoupon = (orderNo,couponCode) => axios.post("/recharge-server/api/v1/coupon/add_coupon/"+orderNo+"/"+couponCode);

// 修改优惠券状态
export const updateCoupon1 = (order_no,is_state,order_number) => axios.post("/recharge-server/api/v1/coupon/update_coupon/"+order_no+"/"+is_state+"/"+order_number);
export const updateCoupon = (data) => axios({
    method:"post",
    url:"/recharge-server/api/v1/coupon/update_coupon",
    data:data
})

//获取邀请列表
export const getSendingCouponList = (sending_user_id) => axios.get("/recharge-server/api/v1/coupon/sending_coupon_list/"+sending_user_id);



/**
 * 商品订单相关
 */

// 创建订单
export const addOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/confirm_order",
    data:data
})
// 获取微信支付 二维码
export const getWeChatCodeUrl = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/we_chat_pay",
    data:data
})
// 获取空中云汇创建的支付信息
export const createAirwallexOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/create_airwallex_order",
    data:data
})
// 查询空中云汇是否支付成功，并修改订单状态
export const airwallextPayCallback = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/airwallext_pay_callback",
    data:data
});


// 根据订单号获取订单状态
export const getWeCahtOrderPayState = (out_trade_no) => axios.get("/order-server/api/v1/order/we_chat_query_pay_success/"+out_trade_no);


// 订单列表
export const getOrderList = (student_id) => axios.get("/order-server/api/v1/order/order_list/"+student_id);

// 订单详细
export const getOrderDetail = (order_id) => axios.get("/order-server/api/v1/order/order_detail/"+order_id);



// 测试
export const get_user = () => axios.get("/order-server/api/v1/order/get_user");
