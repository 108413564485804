<template>
  <div class="detail_main_box">
    <div class="detail_title">{{$t("order.title1")}}</div>
    <div class="detail_box">
      <div class="detail_box_title">{{$t("order.title2")}} {{ order?.payState==2?$t("index.pddate") == 1 ? "已支付" : "Paid":$t("index.pddate") == 1 ? "未支付" : "Unpaid" }} </div>
      <div class="detail_text">{{$t("order.user1")}}: {{order?.userName}}</div>
      <div class="detail_text"> {{$t("order.user2")}}: {{$t("index.pddate") == 1 ? order?.courseZhName : order?.courseEnName}}  </div>
      <div class="detail_text"> {{$t("order.user4")}}: {{order?.courseTime}} {{$t("order.user11")}} </div>
      <div class="detail_text"> {{$t("order.user3")}}: {{order?.rawardTime}} {{$t("order.user11")}} </div>
      <!-- <div class="detail_text"> {{$t("order.user5")}}: {{$t("order.user12")}}/{{$t("order.user13")}}(20% off); </div> -->
      <div class="detail_text"> {{$t("order.user6")}}: {{order?.originalPrice}} USD</div>
      <div class="detail_text"> {{$t("order.user7")}}: {{order?.actualPayment}} {{order?.moneyType==1?"USD":order?.moneyType==2?"RMB":"SGD"}} </div>
      <div class="detail_text"> {{$t("order.user8")}} : {{order?.orderNo}} </div>
      <div class="detail_text"> {{$t("order.user9")}}: {{order?.addDate}}  </div>
      <div class="detail_text" v-if="order?.payDate"> {{$t("order.user10")}}: {{order?.payDate}}  </div>
      <div class="detail_text" v-if="order?.cancelDate"> {{$t("order.user10")}}: {{order?.cancelDate}}  </div>
    </div>
  </div>
</template>

<script>
import { useRouter,useRoute } from "vue-router";
import { reactive, toRefs, onMounted } from "vue";
import { getOrderDetail } from "@/api/getData";
export default {
  name: "VueStudentContract",
  components: {},
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const obj = reactive({ order:null });
    const orderDetail = async (user_id)=>{
      const res = await getOrderDetail(user_id);
      obj.order = res.data.data;
      
    };
    onMounted(()=>{
      let user_id = route.query.user_id;
      orderDetail(user_id);
    })
    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.my_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
}
.detail_main_box {
  width: 800px;
  min-height: 948px;
  margin: 20px auto 50px auto;
  box-sizing: border-box;
  .detail_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    box-sizing: border-box;
    padding-left: 25px;
    color: @xtxColor;
  }
  .detail_box {
  width: 800px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px 25px 20px 25px;
    position: relative;
    margin-bottom: 220px;
    .detail_box_title{
      font-size:20px;
      width:100%;
      line-height: 40px;
      color:#383838;
    }
    .detail_text{
      font-size:16px;
      width:100%;
      line-height: 30px;
      color:#383838;
    }
  }
}
</style>
