<template>
  <div class="app-ment-nav clearfix">
    <div class="ment-box">
      <div class="top_button">
        <el-button :class="buttonList[0].type" @click="buttonClick(0)">{{
          $t("intment.top_button1")
        }}</el-button>
        <el-button :class="buttonList[1].type" @click="buttonClick(1)">{{
          $t("intment.top_button2")
        }}</el-button>
        <el-button :class="buttonList[2].type" @click="buttonClick(2)">{{
          $t("intment.top_button3")
        }}</el-button>
      </div>

      <div class="top_data_bottom">
        <div class="thisweeb_box">
          <el-button class="data_zy" @click="getHeader(-7)"
            ><i class="iconfont icon-xiangzuojiantou"></i
          ></el-button>
          <el-button class="my_thisweek" @click="this_week">{{
            $t("intment.this_week")
          }}</el-button>
          <el-button class="data_zy" @click="getHeader(7)"
            ><i class="iconfont icon-xiangyoujiantou"></i
          ></el-button>
        </div>

        <div class="type_data">
          <el-dropdown>
            <el-button class="week_type">
              <i class="iconfont icon-shijian"></i>
              <span>{{ $t("intment.dateType" + (resource + 1)) }}</span>
              <i class="iconfont icon-xiala1"></i>
            </el-button>
            <template #dropdown>
              <el-radio-group v-model="resource">
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(item, index) in language" :key="index">
                    <el-radio :label="index">
                      {{ $t("intment.dateType" + (index + 1)) }}</el-radio
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-radio-group>
            </template>
          </el-dropdown>
        </div>

        <div class="top_user" v-if="1 == 0">
          <img src="../../assets/img/tou.png" alt="" />
          <span>Jennie.Deng</span>
        </div>
      </div>

      <div class="table_box">
        <table>
          <tr class="table_name">
            <th v-for="(item, index) in tablename" :key="index">
              <div :style="item.text == dqdate ? 'color: #8B94E0;font-weight:bold;' : ''">
                {{ $t("intment.weeks" + (index + 1)) }}
              </div>
              <div :style="item.text == dqdate ? 'color: #8B94E0;font-weight:bold;' : ''">
                {{ item.text }}
              </div>
            </th>
          </tr>
          <tr v-for="(item, index) in OnedayList" :key="index" class="table_list">
            <td>{{ item.top }}</td>
            <td
              v-for="(ilist, d) in item.list"
              :key="d"
              @click="fangfa(index, d, ilist.lstype)"
              :class="ilist.lstype == 0 ? '' : ilist.type == 1 ? 'tdClick' : 'lstdClick'"
            >
              {{ ilist.name }}
            </td>
          </tr>
        </table>
      </div>
      <div class="ConfirmBox">
        <div class="cf_box_date">
          <el-dropdown v-for="(item, index) in keShi" :key="index">
            <span v-if="$t('index.pddate') == 2"
              >{{ ConfirmType == 1 ? (cstype == 1 ? "Single-" : "Cycle-") : ""
              }}{{ item }}
              <i v-if="ConfirmType == 1" class="iconfont icon-xialajiantouxiao"></i
            ></span>
            <span v-else
              >{{ ConfirmType == 1 ? (cstype == 1 ? "单次-" : "循环-") : "" }}{{ item }}
              <i v-if="ConfirmType == 1" class="iconfont icon-xialajiantouxiao"></i
            ></span>
            <template v-if="ConfirmType == 1" #dropdown>
              <el-radio-group v-model="cstype">
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-radio label="1">{{ $t("intment.Single") }}</el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-radio label="2">{{ $t("intment.Cycle") }}</el-radio>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-radio-group>
            </template>
          </el-dropdown>
        </div>
        <el-button v-if="ConfirmType == 0" :class="colorClick">
          {{ $t("index.pddate") == 1 ? "确认约课" : "Confirm" }}</el-button
        >
        <el-button v-if="ConfirmType == 1" :class="colorClick">
          {{ $t("index.pddate") == 1 ? "确认取消" : "Confirm" }}
        </el-button>
        <el-button v-if="ConfirmType == 2" :class="colorClick">
          {{ $t("index.pddate") == 1 ? "确认加课" : "Confirm" }}
        </el-button>
      </div>
      <!-- <div class="tips">
        <i class="iconfont icon-tishi" style="color: red"></i>
        <span>{{ $t("intment.tishi") }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Appyoutopnav from "./components/Appyoutopnav.vue";
import Appyoutopdown from "./components/Appyoutopdown.vue";
import Appcarousel from "./components/Appcarousel.vue";
import { toRefs, reactive, onMounted, ref, h } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  name: "VueAppointment",
  components: {
    Appyoutopnav,
    Appyoutopdown,
    Appcarousel,
  },
  setup(props) {
    const store = useStore();
    let obj = reactive({
      userinfo: store.state.User.userinfo,
      buttonList: [{ type: "el-button-one-click" }, { type: "" }, { type: "" }],
      xingqi: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      weeks: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      tablenameEn: [
        { name: "Lenbth", text: "55min" },
        {
          name: "MON",
        },
        {
          name: "TUE",
        },
        {
          name: "WED",
        },
        {
          name: "THU",
        },
        {
          name: "FRI",
        },
        {
          name: "SAT",
        },
        {
          name: "SUN",
        },
      ],
      tablenameZn: [
        { name: "时长", text: "55min" },
        { name: "星期一" },
        { name: "星期二" },
        { name: "星期三" },
        { name: "星期四" },
        { name: "星期五" },
        { name: "星期六" },
        { name: "星期日" },
      ],
      tablename: null,
      EnZn: 1,
      zouqi: 0,
      mytoday: null,
      chaoZuoTime: null,
      OnedayList: [],
      languageEn: [
        "Beijing",
        "Singapore",
        "Vancouver",
        "London",
        "Paris",
        "New York",
        "Tokyo",
        "Sydney",
      ],
      languageZn: [
        "北京时间",
        "新加坡时间",
        "温哥华时间",
        "伦敦时间",
        "巴黎时间",
        "纽约时间",
        "东京时间",
        "悉尼时间",
      ],
      language: ["Beijing"],
      resource: 0,
      dqdate: 0,
      colorClick: "ConfirmBox-one-click",
      ConfirmType: 0,
      cstype: 1,
      keShi: [],
    });

    // 顶部分类 begin
    const buttonClick = (index) => {
      const buttonList = obj.buttonList;
      for (let i = 0; i < buttonList.length; i++) {
        buttonList[i].type = "";
      }
      let click = "";
      switch (index) {
        case 0:
          click = "el-button-one-click";
          obj.colorClick = "ConfirmBox-one-click";
          obj.ConfirmType = 0;
          break;
        case 1:
          click = "el-button-two-click";
          obj.colorClick = "ConfirmBox-two-click";
          obj.ConfirmType = 1;
          break;
        case 2:
          click = "el-button-three-click";
          obj.colorClick = "ConfirmBox-three-click";
          obj.ConfirmType = 2;
          break;

        default:
          break;
      }
      buttonList[index].type = click;
    }; // end

    //
    const scRiQi = () => {
      let OnedayList = [{}];
      let num = 7;
      let fz = 55;
      for (let i = 0; i < 16; i++) {
        let sz = {};
        let xs = num + i;
        if (xs >= 10) {
          sz["top"] = `${xs}:00—${xs}:${fz}`;
          let pd = 0;
          // if (fanwsj(7, 1) == 2) {
          //   pd = 1;
          // }
          sz["list"] = [
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: pd, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: pd, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: 0, name: "" },
          ];
          OnedayList[i] = sz;
        } else {
          sz["top"] = `0${xs}:00—0${xs}:${fz}`;
          let pd = 0;
          // if (fanwsj(7, 1) == 2) {
          //   pd = 1;
          // }
          sz["list"] = [
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: pd, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: pd, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: 0, name: "" },
            { type: 0, lstype: 0, name: "" },
          ];
          OnedayList[i] = sz;
        }
      }
      obj.OnedayList = OnedayList;
    };

    const fanwsj = (Max, Min) => {
      return Math.floor(Math.random() * (Max - Min + 1)) + Min;
    };

    const tablename = obj.EnZn == 0 ? obj.tablenameZn : obj.tablenameEn;
    const getTime = (time) => {
      //time = 7下星期  -7上星期   0本星期
      let date = new Date(); //chaoZuoTime
      let currentFirstDate = new Date(date);

      let formatDate = function (date) {
        let year = date.getFullYear(); //年
        let month = date.getMonth() + 1; //月
        let day = date.getDate(); //日;
        // let arr = ["日", "一", "二", "三", "四", "五", "六"];
        let week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][
          date.getDay()
        ];
        //我需要的格式是  （星期几 月）
        return month + "/" + day;
      };

      let addDate = function (date, n) {
        date.setDate(date.getDate() + n);
        return date;
      };

      let setDate = function (date) {
        let week = date.getDay() - 1; //控制 日开头还是一开头
        date = addDate(date, week * -1);
        currentFirstDate = new Date(date);
        let weekList = [];
        for (let i = 0; i < 7; i++) {
          weekList[i] = formatDate(i == 0 ? date : addDate(date, 1));
          tablename[i + 1]["text"] = weekList[i];
        }
        return weekList;
      };
      obj.tablename = tablename;
      let thisWeek = setDate(addDate(currentFirstDate, time)); //本星期
    };

    const getHeader = (num) => {
      //上星期
      if (num == -7) {
        var obj1 = obj;
        obj1.zouqi = obj.zouqi - 7;
        obj = obj1;
        getTime(obj1.zouqi);
      } else {
        var obj1 = obj;
        obj1.zouqi = obj.zouqi + 7;
        obj = obj1;
        getTime(obj1.zouqi);
      }
    };

    const this_week = () => {
      var obj1 = obj;
      obj1.zouqi = 0;
      obj = obj1;
      getTime(0);
    };

    const fangfa = (index, lindex, lstype) => {
      if (lstype == 0) {
        let str = localStorage.getItem("language");
        ElNotification({
          title: str == "zh" ? "错误" : "Error",
          message: h(
            "i",
            { style: "color: #8B94E0" },
            str == "zh"
              ? "对不起，没有老师可以安排上课！"
              : "Sorry, there are no teachers to schedule classes!"
          ),
        });
        return false;
      }

      let str = `${obj.tablename[lindex + 1].text} ${obj.tablename[lindex + 1].name} ${
        obj.OnedayList[index].top
      }`;

      obj.keShi.push(str);
      obj.OnedayList[index].list[lindex].type = 1;
      obj.OnedayList[index].list[lindex].name = obj.OnedayList[index].top;
    };

    let formatDate = function () {
      let date = new Date();
      let year = date.getFullYear(); //年
      let month = date.getMonth() + 1; //月
      let day = date.getDate(); //日;
      // let arr = ["日", "一", "二", "三", "四", "五", "六"];
      let week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][
        date.getDay()
      ];
      //我需要的格式是  （星期几 月）
      return month + "/" + day;
    };

    const { t } = useI18n();
    // 初始化生命星期期 begin
    onMounted(() => {
      obj.language = obj.EnZn == 0 ? obj.languageZn : obj.languageEn;
      obj.mytoday = new Date();
      getTime(0);
      scRiQi();
      obj.dqdate = formatDate();
    });

    return {
      ...toRefs(obj),
      buttonClick,
      getHeader,
      this_week,
      fangfa,
    };
  },
};
</script>

<style lang="less" scoped>
.app-ment-nav {
  box-sizing: border-box;
  padding-top: 45px;
  margin-left: 73px;
  width: 1098px;
  .ment-box {
    width: 1098px;
    min-height: 892px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
    box-sizing: border-box;
    padding: 19px;
    .tips {
      width: 100%;
      font-size: 12px;
      color: #5f84c7;
      i {
        font-size: 12px;
        margin-right: 10px;
      }
    }
    .ConfirmBox {
      display: flex;
      margin-top: 10px;
      .my_Confirm {
        background: #7580e5;
      }
      .ConfirmBox-one-click {
        background: #7580e5 !important;
        color: #fff;
      }
      .ConfirmBox-two-click {
        background: #ff8d1a !important;
        color: #fff;
      }
      .ConfirmBox-three-click {
        background: #56b854 !important;
        color: #fff;
      }
      .el-button {
        width: 124px;
        height: 34px;
        opacity: 1;
        border-radius: 7px;
        font-size: 20px;
        letter-spacing: 1px;
      }
      .cf_box_date {
        width: 930px;
        padding: 5px;
        cursor: pointer;
        font-size: 18px;
        i {
          margin-left: 5px;
          font-size: 15px;
          color: #fff;
        }
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 5px;
          font-size: 13px;
          padding: 5px 10px 5px 10px;
          color: #fff;
          background: rgba(95, 132, 199, 1);
          border-radius: 15px;
        }
      }
    }
    .table_box {
      width: 100%;
      table {
        width: 100%;
        .table_list {
          width: 100%;
          height: 36px;
          line-height: 36px;
          display: flex;
          text-align: center;
          border-bottom: 1px solid rgba(199, 199, 199, 1);
          td {
            white-space: nowrap;
            width: 12.5%;
            font-size: 18px;
            cursor: pointer;
            color: rgba(95, 132, 199, 1);
            box-sizing: border-box;
            height: 36px;
            border-right: 1px solid rgba(199, 199, 199, 1);
            background: #fff;
          }
          td:nth-child(1) {
            width: 13%;
            border-left: 1px solid rgba(199, 199, 199, 1);
            font-size: 18px;
          }
          td:hover {
            background: rgba(187, 227, 163, 1);
            color: #fff;
          }
          .tdClick {
            background: rgba(187, 227, 163, 1);
            color: #fff;
          }
          .lstdClick {
            background: #8b94e0;
            color: #fff;
          }
          td:nth-child(1):hover {
            width: 13%;
            border-left: 1px solid rgba(199, 199, 199, 1);
          }
        }
        .table_name {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          width: 100%;
          height: 56px;
          padding: 5px 0;
          display: flex;
          background-color: rgba(247, 247, 247, 1);
          // box-sizing: border-box;
          border: 1px solid rgba(199, 199, 199, 1);
          th {
            white-space: nowrap;
            width: 12.5%;
            height: 56px;
            box-sizing: border-box;
            display: inline-block;
            font-weight: 400;
            color: #808080;
            font-size: 20px;
            div:nth-child(2) {
              box-sizing: border-box;
              font-weight: 100;
            }
          }
          th:nth-child(1) {
            box-sizing: border-box;
            width: 13%;
          }
        }
      }
    }

    .top_data_bottom {
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      position: relative;
      div {
        display: inline-block;
      }
      .top_user {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 140px;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        vertical-align: middle;
        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }
        span {
          vertical-align: middle;
          color: #5f84c7;
          font-size: 20px;
        }
      }

      .el-dropdown {
        position: absolute;
        top: 0;
        left: 50%;
        min-width: 170px;
        height: 40px;
        margin-left: -85px;
        .week_type {
          min-width: 170px;
          height: 40px;
          font-size: 20px;
          margin-left: -85px;
          i {
            font-size: 20px !important;
          }
          span {
            margin: 0 20px;
          }
        }
      }
      /deep/.week_type {
        position: absolute;
        top: 0;
        left: 50%;
        i {
          font-size: 20px;
        }
      }
      /deep/.data_zy {
        width: 40px;
        height: 40px;
      }
      /deep/.my_thisweek {
        height: 40px;
        font-size: 20px;
      }
    }

    .top_button {
      width: 100%;
      height: 72px;
      box-sizing: border-box;
      /deep/.el-button {
        margin-right: 30px;
        height: 50px;
        font-size: 22px;
        box-shadow: 0px 0px 3px #ccc;
        border-radius: 8px;
      }

      .el-button-one-click {
        background-color: #7580e5;
        color: #fff;
      }
      .el-button-two-click {
        background-color: #ff8d1a;
        color: #fff;
      }
      .el-button-three-click {
        background-color: #56b854;
        color: #fff;
      }
    }
  }
}
</style>
