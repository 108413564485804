<template>
  <div
    :class="
      $t('index.pddate') == 1
        ? 'Discount_main_box'
        : 'Discount_main_box Discount_main_en_box'
    "
  >
    <div class="discount_box">
      <div class="dis_title">{{ $t("discount.title") }}</div>
      <div class="dis_zy_box">
        <div class="dis_zy_zuo">
          <div>
            <i class="iconfont icon-duigouxiao"></i>
            <span>{{ $t("discount.Gift") }}</span>
          </div>
          <div>
            <i class="iconfont icon-duigouxiao"></i>
            <span>{{ $t("discount.Members") }}</span>
          </div>
          <div>
            <img src="../../assets/img/lanliwu.png" alt="" />
          </div>
        </div>
        <div :class="$t('index.pddate') == 1 ? 'dis_zy_yuo' : 'dis_zy_yuo dis_zy_en_yuo'">
          <div class="dis_y_text" v-html="$t('discount.text1')"></div>
          <div class="dis_y_text" v-html="$t('discount.text2')"></div>
          <div class="dis_y_text" v-html="$t('discount.text3')"></div>
          <div class="dis_y_text" v-html="$t('discount.text4')"></div>
          <div class="dis_y_tit">
            {{ $t("discount.text5") }}
          </div>
        </div>
      </div>
      <div class="key_box">
        <span>{{ $t("discount.text6") }}</span>
      </div>
      <div class="scan_box">
        <div class="scan_box_zuo">
          <div class="scan_title">{{ $t("discount.title1") }}</div>
          <div class="scan_code_box">
            <div>
              <vue-qr
                :correctLevel="3"
                :autoColor="false"
                colorDark="#31A8E1"
                :text="codeUrl"
                :size="200"
                :margin="0"
                :logoMargin="3"
              ></vue-qr>
            </div>
            <div class="en_suojing">
              <div><span>1</span> {{ $t("discount.text7") }}</div>
              <div><span>2</span> {{ $t("discount.text8") }}</div>
              <div><span>3</span> {{ $t("discount.text9") }}</div>
            </div>
            <img class="youj" src="../../assets/img/youj.png" alt="" />
          </div>
        </div>
        <div class="scan_box_you">
          <div class="scan_title">{{ $t("discount.title2") }}</div>
          <div class="scan_fwtw">
            <div>
              <img src="../../assets/img/facebook.png" alt="" /> <span>Facebook</span>
            </div>
            <div><img src="../../assets/img/wchat.png" alt="" /> <span>WeChat</span></div>
            <div>
              <img src="../../assets/img/twitter.png" alt="" /> <span>Twitter</span>
            </div>
            <div>
              <img src="../../assets/img/whatsapp.png" alt="" /> <span>WhatsApp</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inv_ach_box">
        <div class="inv_title en_suojing1">{{ $t("discount.title3") }}</div>
        <div class="inv_img_box">
          <div class="inv_box_left">
            <div>{{ $t("discount.text101") }}</div>
            <div>{{ $t("discount.text10") }}</div>
            <div><span>{{listSize}}</span> {{ $t("discount.text11") }}</div>
            <img src="../../assets/img/inv.png" alt="" />
          </div>
          <div class="inv_box_right">
            <div><i class="iconfont icon-jiaxingshoucangtianchong"></i></div>
            <div>{{ $t("discount.text12") }}</div>
            <div><span>0</span> {{ $t("discount.text13") }}</div>
            <img src="../../assets/img/inv.png" alt="" />
          </div>
        </div>
      </div>
      <div class="dis_title_inv en_suojing1">{{ $t("discount.title4") }}</div>
      <div class="dis_list_box">
        <div class="dis_list_title en_suojing1">
          <div>{{ $t("discount.text14") }}</div>
          <div>{{ $t("discount.text15") }}</div>
          <div>{{ $t("discount.text16") }}</div>
          <div>{{ $t("discount.text17") }}</div>
        </div>
        <div class="dis_list_hid">
          <div v-if="xiansList!=null" v-for="(item, index) in xiansList" class="dis_list">
            <div>{{ item.quilt_user_name }}</div>
            <!-- <div>{{item.quilt_user_id}}</div> -->
            <div>
              {{ $t("index.pddate") == 1 ? item.course_zh_name : item.course_en_name }}
            </div>
            <div>{{ item.add_time }}</div>
            <div>
              <span>{{ item.sessions }}</span>
            </div>
          </div>
          <!-- <div class="dis_list">
            <div>zhangsan</div>
            <div>{{ $t("index.pddate") == 1 ? "HSK水平考试" : "HSK Course" }}</div>
            <div>22—10—21</div>
            <div> <span>2</span> </div>
          </div>-->
        </div>
        <div class="Turn_box" v-if="listSize>5">
          <i class="iconfont icon-xiangzuojiantou" @click="fanyeClick(-5)"></i>
          <span>{{ $t("index.pddate") == 1 ? "翻页" : "Turn" }}</span>
          <i class="iconfont icon-xiangyoujiantou" @click="fanyeClick(+5)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, onMounted } from "vue";
import AppUserHeader from "./componentrecharge/AppUserHeader.vue";
import VueQr from "vue-qr/src/packages/vue-qr.vue";
import { ElMessageBox } from "element-plus";
import { getSendingCouponList } from "@/api/getData";
export default {
  name: "VueStudentDiscount",
  components: { AppUserHeader, VueQr },
  setup() {
    const router = useRouter();
    const obj = reactive({
      yhUrl: "https://share.zhixingchinese.com",
      codeUrl: "https://share.zhixingchinese.com",
      current_student: null,
      sendingList: null,
      xiansList: [],
      num: 0,
      listSize:0
    });
    const pad = (num, n) => {
      let sz = num + "";
      for (let i = 0; i < n; i++) {
        if (i >= Array.from(sz).length) {
          sz = "0" + sz;
        }
      }
      return sz;
    };

    const getUserList = async () => {
      if (obj.current_student != null) {
        let res = await getSendingCouponList(obj.current_student.id);
        obj.sendingList = res.data.data;
        obj.listSize = obj.sendingList.length;
        fanye();
      }
    };

    const fanyeClick = (num) => {
      let pdNum = obj.num + num;
      if (obj.listSize > pdNum && num>0) {
        obj.num += num;
      }
      if (num<0) {
        obj.num += num;
      }
      if (obj.num < 0) {
        obj.num = 0;
      }
      fanye();
    };

    const fanye = () => {
      let sdlist = obj.sendingList;
      let x = 0;
      for (let i = obj.num; i < 5 + obj.num; i++) {
        if (sdlist[i] != null) {
          obj.xiansList[x] = sdlist[i];
        }else{
          obj.xiansList[x] = [];
        }
        x++;
      }
    };

    onMounted(() => {
      obj.current_student =
        localStorage.getItem("current_student") == undefined
          ? null
          : JSON.parse(localStorage.getItem("current_student"));
      getUserList();
      let lang = localStorage.getItem("language");
      let student_list = localStorage.getItem("student_list");
      if (student_list != null && student_list != "null" && student_list != "[]") {
        student_list = JSON.parse(student_list);
        if (student_list.length == 1) {
          obj.codeUrl =
            obj.yhUrl + "?fenxiangid=81" + pad(student_list[0].id, 4) + "&lang=" + lang;
        } else {
          let list = "";
          for (let i = 0; i < student_list.length; i++) {
            list += "81"+pad(student_list[i].id, 4) + "|" + student_list[i].nickname + "-";
          }
          obj.codeUrl = obj.yhUrl + "?data=" + list + "&lang=" + lang;
        }
      } else {
        obj.codeUrl = obj.yhUrl + "?data=404" + "&lang=" + lang;
      }
    });
    return {
      ...toRefs(obj),
      fanyeClick,
    };
  },
};
</script>

<style lang="less" scoped>
.Discount_main_en_box {
  font-family: "Inter", sans-serif !important;
  .en_suojing {
    letter-spacing: -0.003rem;
  }
  .en_suojing1 {
    letter-spacing: -0.005rem;
  }
  .discount_box {
    .scan_box {
      .scan_title {
        letter-spacing: -0.007rem;
      }
      .scan_box_you {
        .scan_title {
          text-align: right !important;
        }
      }
    }
  }
}
.Discount_main_box {
  font-family: "AlibabaPuHuiTi-3-55-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 905px;
  margin: 45px auto;
  box-sizing: border-box;
  .discount_box {
    width: 905px;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 20px 90px;
    .dis_list_box {
      width: 100%;
      box-shadow: 0px 0px 7px #ccc;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 0 30px 0px 30px;
      .Turn_box {
        height: 60px;
        line-height: 60px;
        color: #b5b1b1;
        font-size: 18px;
        i {
          cursor: pointer;
          font-size: 18px;
        }
        span {
          cursor: pointer;
          margin: 0 10px;
        }
      }
      .dis_list_hid {
        width: 100%;
        min-height: 180px;
      }
      .dis_list {
        display: flex;
        width: 100%;
        text-align: left;
        justify-content: space-around;
        font-size: 20px;
        div {
          height: 40px;
          line-height: 40px;
          flex: 1;
          color: rgba(181, 177, 177, 1);
        }
        div:nth-child(2) {
          text-align: center;
          flex: 1.3;
        }
        div:nth-child(3) {
          text-align: center;
          flex: 1.3;
        }
        div:nth-child(4) {
          // flex: 0.7;
          text-align: right;
          box-sizing: border-box;
          position: relative;
          span {
            position: absolute;
            right: 34px;
          }
        }
      }
      .dis_list_title {
        display: flex;
        width: 100%;
        text-align: left;
        justify-content: space-around;
        border-bottom: 2px solid #e5e5e5;
        font-size: 20px;
        div {
          height: 40px;
          line-height: 40px;
          flex: 1;
          color: #5f84c7;
        }
        div:nth-child(2) {
          text-align: center;
          flex: 1.3;
        }
        div:nth-child(3) {
          text-align: center;
          flex: 1.3;
        }
        div:nth-child(4) {
          // flex: 0.7;
          text-align: right;
        }
      }
    }
    .dis_title_inv {
      width: 100%;
      padding-left: 20px;
      height: 70px;
      line-height: 80px;
      font-size: 24px;
      color: #5f84c7;
    }
    .inv_ach_box {
      width: 100%;
      border-top: 4px solid #e5e5e5;
      padding-left: 20px;
      box-sizing: border-box;
      .inv_img_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 31px;
        padding-right: 50px;
        .inv_box_right {
          position: relative;
          width: 251px;
          height: 173px;
          color: #fff;
          // margin-right: 25px;
          div:nth-child(1) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 60px;
            line-height: 70px;
            text-align: center;
            font-size: 25px;
            i {
              font-size: 25px;
              color: #ffcb9f;
            }
          }
          div:nth-child(2) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 40px;
            font-size: 22px;
            text-align: center;
          }
          div:nth-child(3) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 40px;
            font-size: 20px;
            text-align: center;
            span {
              font-size: 26px;
            }
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .inv_box_left {
          position: relative;
          width: 251px;
          height: 173px;
          color: #fff;
          // margin-right: 70px;
          div:nth-child(1) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 60px;
            line-height: 90px;
            text-align: center;
            font-size: 22px;
          }
          div:nth-child(2) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 40px;
            font-size: 22px;
            text-align: center;
          }
          div:nth-child(3) {
            position: relative;
            z-index: 20;
            width: 100%;
            height: 40px;
            font-size: 20px;
            text-align: center;
            span {
              font-size: 26px;
            }
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .inv_title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        color: #5f84c7;
        font-size: 24px;
      }
    }
    .scan_box {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding-left: 20.78px;
      border-top: 4px solid #e5e5e5;
      position: relative;
      .youj {
        position: absolute;
        top: 155px;
        left: 383px;
        width: 40px;
        height: 24px;
      }
      .scan_box_you {
        flex: 2;
        .scan_title {
          width: 100%;
          height: 60px;
          line-height: 78px;
          color: #5f84c7;
          font-size: 22px;
          // margin-left:70px;
          text-align: center;
        }
        .scan_fwtw {
          width: 200px;
          height: 200px;
          border-radius: 10px;
          box-shadow: 0px 0px 7px #ccc;
          box-sizing: border-box;
          padding: 10px 10px 1px 10px;
          text-align: center;
          margin-top: 15px;
          float: right;
          margin-right: 33.82px;
          div {
            width: 100%;
            height: 37px;
            line-height: 37px;
            background-color: #f0f0f2;
            color: #31a8e1;
            border-radius: 5px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            vertical-align: middle;
            img {
              margin-top: 5px;
              margin-left: 28px;
              width: 26.61px;
              height: 25.76px;
            }
            span {
              width: 100px;
              font-size: 16px;
              display: inline-block;
              text-align: left;
              vertical-align: middle;
            }
          }
        }
      }
      .scan_box_en_zuo {
      }
      .scan_box_zuo {
        flex: 3;
        .scan_title {
          width: 100%;
          height: 60px;
          line-height: 78px;
          color: #5f84c7;
          font-size: 24px;
        }
        .scan_code_box {
          width: 100%;
          display: flex;
          padding: 40px 0px 40px 5px;
          div:nth-child(1) {
            background-color: #fff;
            margin-right: 9.22px;
            img {
              border-radius: 10px;
              box-shadow: 0px 0px 3px #ccc;
              box-sizing: border-box;
              padding: 15px;
              width: 141px;
              height: 136px;
            }
          }
          div:nth-child(2) {
            font-size: 16px;
            color: #5f84c7;
            padding-top: 2px;
            box-sizing: border-box;
            div {
              height: 55px;
              span {
                display: inline-block;
                width: 22px;
                height: 22px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22px;
                border-radius: 50%;
                background-color: #5f84c7;
                color: #fff;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .key_box {
      text-align: center;
      width: 550px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      background-color: #31a8e1;
      border-radius: 16px;
      margin: 18px auto 34.65px auto;
      span {
        color: #fff;
        font-size: 20px;
      }
    }
    .dis_y_tit {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 27.44px;
      margin-top: 8.5px;
      width: 100%;
      color: #ffcb9f;
    }
    .dis_zy_en_yuo {
      font-size: 20px;
      font-weight: 400;

      .dis_y_text:nth-child(1) {
        line-height: 24.2px;
        letter-spacing: -0.003rem;
      }
      .dis_y_text:nth-child(2),
      .dis_y_text:nth-child(3),
      .dis_y_text:nth-child(4) {
        line-height: 24.2px;
        letter-spacing: -0.005rem;
      }
    }
    .dis_zy_yuo {
      white-space: nowrap;
      width: 480px;
      .dis_y_text {
        width: 100%;
        font-size: 20px;
        color: #5f84c7;
        height: 59px;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 27.44px;
        color: rgba(95, 132, 199, 1);
        margin-bottom: 4.5px;
        /deep/p {
          margin: 0;
        }
        /deep/i {
          color: #ffcb9f;
          font-size: 24px;
          margin-left: 5px;
        }
      }
      .dis_y_text:nth-child(1) {
        height: 55px;
        box-sizing: border-box;
      }
      .dis_y_text:nth-child(2) {
        margin-top: 17px;
      }
    }
    .dis_zy_box {
      width: 100%;
      display: flex;
      margin-top: 7px;
      .dis_zy_zuo {
        width: 263px;
        margin-left: -14px;
        margin-top: 2px;
        div:nth-child(1),
        div:nth-child(2) {
          width: 100%;
          height: 35px;
          color: #5f84c7;
          line-height: 35px;
          margin-bottom: 37px;
          i {
            font-size: 31px;
            margin-right: 12px;
            vertical-align: top;
          }
          span {
            text-align: center;
            vertical-align: top;
            display: inline-block;
            font-size: 20px;
            font-weight: 400;
            background-color: #5f84c7;
            color: #fff;
            border-radius: 20px;
            width: 200px;
            height: 35px;
          }
        }
        div:nth-child(3) {
          width: 100%;
          box-sizing: border-box;
          padding: 17px 28px 0px 36px;
          img {
            display: inline-block;
            width: 121.39px;
            height: 117.51px;
          }
        }
      }
    }
    .dis_title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #5f84c7;
      font-size: 24px;
      box-sizing: border-box;
      padding-left: 21px;
    }
  }
}
</style>
