<template>
  <div class="app-msg-nav clearfix">
    <Appyoutopnav />
    <div class="message_box">
      <div class="title ellipsis">{{ $t("message.title") }}</div>
      <div class="my_message" ref="scrollRef">
        <div v-for="(item, index) in obj.msgList">
          <div class="msg_list">
            <div class="msg_name">{{ obj.emil }}：</div>
            <div class="msg_text">{{ item.put_text }}</div>
          </div>
          <div v-if="item.get_text != null  && item.get_text!=''" class="msg_list">
            <div class="msg_name">
              {{ $t("message.pddate") == "1" ? "知行客服：" : "Customer service:" }}
            </div>
            <div class="msg_text">{{ item.get_text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Appyoutopnav from "./components/Appyoutopnav.vue";
import { reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { getMessage } from "@/api/getData";

const store = useStore();
let scrollRef = ref(null);
setTimeout(() => {
  scrollRef.value.scrollTop = 511;
}, 200);
const obj = reactive({
  userinfo: store.state.User.userinfo,
  msgList: null,
  emil:null
});

const messageList = async () => {
  let res = await getMessage();
  obj.msgList = res.data.data;
};

onMounted(() => {
  messageList();
  goBottom();
  obj.emil = localStorage.getItem("emil");
});
const goBottom = () => {
  let chatContent = document.getElementsByClassName("my_message")[0];
  chatContent.scrollTop = "200px";
};
</script>

<style lang="less" scoped>
.app-msg-nav {
  box-sizing: border-box;
  padding-top: 45px;
  margin-left: 75px;
  max-width: 856px;
  width: 856px;
  .message_box {
    padding-top: 50px;
    width: 100%;
    .my_message {
      width: 100%;
      height: 690px;
      background: #f7f7f7;
      border-radius: 20px;
      overflow: auto;
      box-sizing: border-box;
      padding: 20px;
      .msg_list {
        .msg_name {
          font-size: 18px;
          color: #5f84c7;
        }
        .msg_text {
          font-size: 17px;
          color: #a6a6a6;
          padding: 5px;
        }
      }
    }
    .my_message::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }
    .title {
      width: 100%;
      height: 50px;
      color: #5f84c7;
      font-size: 22px;
    }
  }
}
</style>
