<template>
  <div class="app-date-nav clearfix">
    <div class="topbox">
      <div class="sd_date">
        <div class="meng_ban_box">
          <div>{{ $t("intment.weeks8") }}</div>
          <div>{{ $t("intment.weeks2") }}</div>
          <div>{{ $t("intment.weeks3") }}</div>
          <div>{{ $t("intment.weeks4") }}</div>
          <div>{{ $t("intment.weeks5") }}</div>
          <div>{{ $t("intment.weeks6") }}</div>
          <div>{{ $t("intment.weeks7") }}</div>
        </div>
        <el-calendar ref="calendar">
          <template #header="{ date }">
            <span class="my_yrar_month">{{ date }}</span>
            <el-button-group>
              <el-button size="small" class="my_icon" @click="selectDate('prev-month')">
                <i class="iconfont icon-xiangzuojiantou"></i>
              </el-button>
              <!-- <el-button size="small" @click="selectDate('today')">Today</el-button> -->
              <el-button size="small" class="my_icon" @click="selectDate('next-month')">
                <i class="iconfont icon-xiangyoujiantou"></i>
              </el-button>
            </el-button-group>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.lists">123</div>
          </template>
          <template #date-cell="{ data }">
            <div class="my_data_box">
              <span
                v-if="
                  oneDay == data.day.split('-')[2] && oneMonth == data.day.split('-')[1]
                "
                class="oneDayclass"
                >{{
                  data.day.split("-")[2] < 10
                    ? data.day.split("-")[2].replace("0", "")
                    : data.day.split("-")[2]
                }}</span
              >
              <span v-else>{{
                data.day.split("-")[2] < 10
                  ? data.day.split("-")[2].replace("0", "")
                  : data.day.split("-")[2]
              }}</span>
              <!-- <div v-if="data.day.split('-')[2] == 3" class="my_data_jilu_xz"></div>
              <div v-if="data.day.split('-')[2] == 17" class="my_data_jilu"></div>
              <div v-if="data.day.split('-')[2] == 10" class="my_data_jilu_xz"></div>
              <div v-if="data.day.split('-')[2] == 24" class="my_data_jilu"></div>
              <div v-if="data.day.split('-')[2] == 31" class="my_data_jilu"></div> -->
            </div>
          </template>
        </el-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref, h } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
export default {
  name: "VueAppscheduledate",
  components: {},
  setup(props) {
    let date = new Date(); //chaoZuoTime
    const store = useStore();
    let obj = reactive({
      userinfo: store.state.User.userinfo,
      oneDay: date.getDate(),
      oneMonth: date.getMonth() + 1,
    });
    const calendar = ref();
    const selectDate = (val) => {
      calendar.value.selectDate(val);
    };
    return {
      ...toRefs(obj),
      selectDate,
      calendar,
    };
  },
};
</script>

<style lang="less" scoped>
.oneDayclass {
  background: #7580e5;
  color: #fff;
  border-radius: 50%;
  padding: 3px 5px;
}
.app-date-nav {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 856px;
  width: 856px;
  .topbox {
    width: 100%;
    .sd_date {
      padding-top: 56px;
      width: 650px;
      min-height: 434px;
      margin: 0 auto;
      position: relative;
      .meng_ban_box {
        position: absolute;
        top: 120px;
        left: 0;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: flex;
        background-color: rgba(212, 227, 255, 1);
        box-sizing: border-box;
        padding-right: 7px;
        div {
          font-size: 22px;
          flex: 1;
          font-weight: 500;
          letter-spacing: 0px;
          color: rgba(157, 166, 186, 1);
        }
      }
    }
    // 日历 外部大盒子
    .el-calendar {
      background: #d4e3ff;
      border-radius: 20px;
      min-height: 434px;
      .my_yrar_month {
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 40.54px;
        color: rgba(117, 128, 229, 1);
      }
      .my_icon {
        box-sizing: border-box;
        padding-top: 19px;
        i {
          font-weight: bolder;
          font-size: 24px;
          margin-left: 10px;
          color: rgba(157, 166, 186, 1);
        }
      }

      /deep/.el-calendar__body {
        padding: 0 0 10px 0 !important;
      }
      // 日历顶部
      /deep/.el-calendar__header {
        border: none !important;
        // 日历按钮
        .el-button--small {
          border: none;
          background: #d4e3ff;
        }
      }

      // 日历小盒子
      /deep/.el-calendar-table td {
        border: none;
      }
      .my_data_box {
        position: relative;
        font-size: 22px;
        .my_data_jilu {
          position: absolute;
          width: 7px;
          height: 7px;
          border: 1px solid #7580e5;
          background: #fff;
          border-radius: 50%;
          top: 10px;
          right: 10px;
        }
        .my_data_jilu_xz {
          position: absolute;
          width: 7px;
          height: 7px;
          border: 1px solid #7580e5;
          background: #7580e5;
          border-radius: 50%;
          top: 10px;
          right: 10px;
        }
      }
      /deep/.el-calendar-table thead th {
        color: #9da6ba;
        font-size: 22px;
      }
      /deep/.el-calendar-table .el-calendar-day {
        width: 90px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-radius: 50%;
        position: relative !important;
        .thead {
          color: red;
        }
      }
      // 日历小盒子 选中状态
      /deep/.el-calendar-table td.is-today {
        color: #000;
        width: 30px !important;
        height: 30px !important;
        background: none;
        position: relative;
        background-size: 20%;
        border-radius: 50%;

        // margin: 20px;
      }

      /deep/.el-calendar-table td.is-today::after {
        content: "";
        border-radius: 0%;
        width: 110%;
        height: 110%;
        background: #7580e5;
        position: absolute;
        top: 72%;
        left: 96%;
        z-index: -1;
        color: #000;
      }
      /deep/.el-calendar-table td.is-selected {
        background: none;
        color: #fff;
        display: inline-block;
        border-radius: 50%;
        // margin: 20px;
        text-align: center;
        // background: #7580e5;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 2;
        color: #000;
        // color: #FFF !important;
      }
      /deep/.el-calendar-table td.is-selected::after {
        content: "";
        border-radius: 50%;
        width: 110%;
        height: 110%;
        background: none;
        // background: #7580e5;
        position: absolute;
        top: 72%;
        left: 96%;
        z-index: -1;
      }
      /deep/.el-calendar-day:hover {
        background: none;
        color: none;
      }
    }
  }
}
</style>
