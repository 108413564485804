import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './common/css/base.less';
import vueEsign from 'vue-esign'
import i18n from '@/language/'
import 'amfe-flexible';
// import './rem.js';
import './utils/mobile-util'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

createApp(App).use(store).use(router).use(i18n).use(ElementPlus).use(vueEsign).mount('#app')
