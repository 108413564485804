<template>
  <div class="app-swiper-box">
    <swiper
      :slidesPerView="3"
      :spaceBetween="20"
      :rewind="true"
      :loop="true"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485130&idx=1&sn=11e2ccece3ca3dfc2aa059c77bc5ac9a&chksm=cf1a5621f86ddf373f2af4fed3e84bb6baf7b7290f325c90c32c8ffeab660247023fc6d63bdf#rd"
        >
          <img class="xmao" src="../../../assets/img/cd.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485115&idx=1&sn=86bed704acd88469c14f93eae69f365b&chksm=cf1a5650f86ddf46115730c80792862663ab8b8701258e6b9c78f43582483bdc6ad34bdcadab#rd"
        >
          <img class="xmao" src="../../../assets/img/gz.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485116&idx=1&sn=0b5daad5ddf52e99f27f49229f145c5c&chksm=cf1a5657f86ddf41dc968ebbc6e88e5e52a59e0c85bd8c9068aaa1a0a8850d58426f52f48088#rd"
        >
          <img class="xmao" src="../../../assets/img/gzx.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485113&idx=1&sn=8bcfbd4f29370e5386faae408bcd629d&chksm=cf1a5652f86ddf447ecd80e53f8135acf14dc42b30af5c8eb1ab726c28bd0c97933317921f11#rd"
        >
          <img class="xmao" src="../../../assets/img/bjin.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485109&idx=1&sn=3ba71efd5a40198bc79c5a48e8ddb1d5&chksm=cf1a565ef86ddf4807d5c2fb0bc1aebf7cdbf54c4a0ad1f6eb3bcdce9882d8e30e4582150e87#rd"
        >
          <img class="xmao" src="../../../assets/img/sh.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485130&idx=1&sn=11e2ccece3ca3dfc2aa059c77bc5ac9a&chksm=cf1a5621f86ddf373f2af4fed3e84bb6baf7b7290f325c90c32c8ffeab660247023fc6d63bdf#rd"
        >
          <img class="xmao" src="../../../assets/img/cd.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485115&idx=1&sn=86bed704acd88469c14f93eae69f365b&chksm=cf1a5650f86ddf46115730c80792862663ab8b8701258e6b9c78f43582483bdc6ad34bdcadab#rd"
        >
          <img class="xmao" src="../../../assets/img/gz.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485116&idx=1&sn=0b5daad5ddf52e99f27f49229f145c5c&chksm=cf1a5657f86ddf41dc968ebbc6e88e5e52a59e0c85bd8c9068aaa1a0a8850d58426f52f48088#rd"
        >
          <img class="xmao" src="../../../assets/img/gzx.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485113&idx=1&sn=8bcfbd4f29370e5386faae408bcd629d&chksm=cf1a5652f86ddf447ecd80e53f8135acf14dc42b30af5c8eb1ab726c28bd0c97933317921f11#rd"
        >
          <img class="xmao" src="../../../assets/img/bjin.png" alt="" />
        </a>
      </swiper-slide>
      <swiper-slide>
        <a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzg3Nzk1NTQ2MA==&mid=2247485109&idx=1&sn=3ba71efd5a40198bc79c5a48e8ddb1d5&chksm=cf1a565ef86ddf4807d5c2fb0bc1aebf7cdbf54c4a0ad1f6eb3bcdce9882d8e30e4582150e87#rd"
        >
          <img class="xmao" src="../../../assets/img/sh.png" alt="" />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import 'swiper/css/navigation';
import "swiper/css/pagination";

// import './style.css';

// import required modules
import { Navigation, Pagination } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>
<style lang="less" scoped>
/deep/.swiper-button-prev:after {
  display: none;
}
/deep/.swiper-button-next:after {
  display: none;
}
/*自定义样式*/
/deep/.swiper-button-prev {
  width: 35px;
  height: 35px;
  background: url("../../../assets/img/zuodong.png");
  background-size: cover;
  top: 150px;
  left: -50px;
  position: fixed;
  z-index: 999999;
  cursor: pointer;
}

/deep/.swiper-button-next {
  width: 35px;
  height: 35px;
  background: url("../../../assets/img/youdong.png");
  background-size: cover;
  top: 150px;
  right: -50px;
  position: fixed;
  z-index: 999999;
  cursor: pointer;
}
.app-swiper-box {
  width: 860px;
  height: 340px;
  margin: 0 auto;
  transform: scale(1);
}
.swiper {
  width: 100%;
  height: 340px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 340px;
  .ss_text {
    position: absolute;
    width: 100%;
    height: 55%;
    z-index: 20;
    background-color: #fff;
    left: 0;
    bottom: 0;
    .ss_xin {
      width: 100%;
      height: 20px;
      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        text-align: center;
        margin: 0 5px;
      }
    }
    .ss_title {
      font-size: 12px;
      margin: 0 auto;
      min-width: 100px;
    }
    .ss_content {
      font-size: 9px;
      color: #a6a6a6;
      text-align: left;
      line-height: 7px;
      box-sizing: border-box;
      padding: 0 15px;
      overflow-y: auto;
    }
  }
}

.swiper-slide .xmao {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
