<template>
  <div class="upwd_box">
    <div class="upwd_title">{{ $t("login.title2") }}</div>
    <div class="upwd_input">
      <input type="text" v-model="userUpdate.oldPwd" :placeholder="$t('login.oldpwd')" />
      <img src="../../../assets/img/jp.png" alt="" />
      <span></span>
    </div>
    <div class="upwd_input">
      <input type="text" v-model="userUpdate.newPwd" :placeholder="$t('login.newpwd')" />
      <img src="../../../assets/img/jp.png" alt="" />
    </div>
    <div class="upwd_input">
      <input type="text" v-model="userUpdate.qrnewPwd" :placeholder="$t('login.thenewpwd')" />
      <img src="../../../assets/img/jp.png" alt="" />
    </div>
    <div class="upwd_sub">
      <div @click="Cancelbox">{{ $t("login.button1") }}</div>
      <div @click="clickUpdate">{{ $t("login.button2") }}</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { updatePassword } from "@/api/getData";
import { ElMessage } from "element-plus"
export default {
  name: "VueStudentContract",
  components: {},
  emits: ["cancelClick"],
  setup(props,context) {
    const router = useRouter();
    const obj = reactive({
      userUpdate:{
        oldPwd:"",
        newPwd:"",
        qrnewPwd:"",
      }
    });
    const Cancelbox = () => {
      context.emit("cancelClick");

      obj.userUpdate = {
        oldPwd:"",
        newPwd:"",
        qrnewPwd:"",
      }
    };
    const clickUpdate = async ()=>{
      let lang = localStorage.getItem("langauge");

      if(obj.userUpdate.oldPwd == null || obj.userUpdate.oldPwd == "" || obj.userUpdate.newPwd == null || obj.userUpdate.newPwd == ""
      || obj.userUpdate.qrnewPwd == null || obj.userUpdate.qrnewPwd == ""){
        
        ElMessage.error(lang == "en" ? "Content cannot be empty!" : "内容不能为空!");
        return false;
      }

      if (obj.userUpdate.newPwd != obj.userUpdate.qrnewPwd) {
        ElMessage.error(
          lang == "en" ? "The two passwords entered do not match!" : "两次输入密码不一致!"
        );
        return false;
      }
      const res = await updatePassword(obj.userUpdate.oldPwd,obj.userUpdate.newPwd);
      ElMessage.success(lang=="en"?"Success":"成功！");
      context.emit("cancelClick");
    }
    return {
      ...toRefs(obj),Cancelbox,clickUpdate
    };
  },
};
</script>

<style lang="less" scoped>
.upwd_box {
  position: fixed;
  top: 120px;
  left: 50%;
  width: 590px;
  margin-left: -295px;
  box-shadow: 0px 0px 7px #ccc;
  background-color: #fff;
  border-radius: 25px;
  box-sizing: border-box;
  padding: 10px 50px 20px 50px;
  z-index: 2000;
  .upwd_sub {
    width: 100%;
    height: 53px;
    line-height: 53px;
    display: flex;
    margin: 55px 0 20px 0;
    justify-content: space-evenly;
    div {
      width: 167px;
      color: #808080;
      border: 1px solid #808080;
      text-align: center;
      border-radius: 50px;
      cursor: pointer;
      font-size: 20px;
    }
    div:hover {
      color: @xtxColor;
      border: 1px solid @xtxColor;
    }
  }

  .upwd_input {
    width: 100%;
    height: 53px;
    line-height: 48px;
    border-radius: 30px;
    border: 1px solid #808080;
    margin-top: 60px;
    vertical-align: middle;
    input {
      width: 80%;
      height: 97%;
      box-sizing: border-box;
      margin-left: 25px;
      border: none;
      outline: none;
      font-size: 20px;
      vertical-align: middle;
    }
    img {
      margin-left: 13px;
      vertical-align: middle;
    }
  }
  .upwd_title {
    width: 100%;
    height: 70px;
    line-height: 130px;
    font-size: 30px;
    text-align: center;
    box-sizing: border-box;
    padding-left: 25px;
    color: #000;
  }
}
</style>
